import React, { useState, useEffect } from "react";
import TextBox2 from "../TextBox2";
import { useDispatch, useSelector } from "react-redux";
import { addProperty } from "../../store/thunks/hostActions";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import back from "../../icons/left.png";
import { addPropertyReset } from "../../store/slices/hostSlices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddProperty() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [checkInTime, setCheckInTime] = useState();
  const [checkOutTime, setCheckoutTime] = useState();

  const [checkinerror, setCheckInError] = useState("");
  const [checkerror, setCheckError] = useState("");

  const handleTimeChange = (selectedTime) => {
    setCheckInTime(selectedTime);
  };

  const handleTimeChange2 = (selectedTime) => {
    setCheckoutTime(selectedTime);
  };

  const [isPropertyAdded, setIsPropertyAdded] = useState(false);

  const { loading, success, error } = useSelector((state) => state.addProperty);
  const [address, setAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
  });

  let [credentials, setCredentials] = useState({
    description: "",
    keyInfo: "",
    listingTitle:""
  });

  const addPropertyHandler = () => {
    if (!checkInTime) {
      toast.error("Check in time required", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    if (!checkOutTime) {
      toast.error("Check out time required", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    } else {
      setCheckInError("");
      setCheckError("");
    }

    dispatch(
      addProperty({ checkInTime, checkOutTime, ...credentials, address })
    );
  };

  useEffect(() => {
    if (success) {
      setIsPropertyAdded(true);
    }

    setTimeout(() => {
      error && dispatch(addPropertyReset());
    }, 2000);
  }, [success, error]);

  return (
    <div className="m-auto shadow-4xl pb-[30px] mobile:pb-5 bg-white my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 overflow-y-scroll scrollbar-thin">
      {/* Heading Add Property with Edit Del and Copy Icons */}
      <div className="w-full m-auto">
        <div className="flex justify-between items-center py-5 px-3 border-b-lightborder border-b-2 w-full">
          <h1 className="text-base3 font-segoe font-semibold mobile:text-base2 flex items-center gap-x-2">
            <img
              src={back}
              alt="back"
              className="w-[30px] h-[20px] inline-block pr-2 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <label>Add New Property</label>
          </h1>
          <div className="flex items-center">
            {loading && (
              <CircularProgress
                color="inherit"
                className="mr-2"
                size={"20px"}
              />
            )}
            {!isPropertyAdded ? (
              <button
                onClick={addPropertyHandler}
                className="bg-[#00B227] text-white font-bold py-2 px-4 rounded-lg mobile:hidden"
              >
                Save Property
              </button>
            ) : (
              <button className=" bg-light text-white font-bold py-2 px-4 rounded-lg mobile:hidden">
                Property Saved
              </button>
            )}
          </div>
        </div>

        {/* Form for Add Property */}
        <div className="w-[95%] m-auto form pt-5">
          <div className="flex items-center gap-x-7 mobile:flex-col">
            <TextBox2
              label={"Address Line 1"}
              name={"line1"}
              credentials={address}
              value={address.line1}
              setValue={setAddress}
              placeholder={"Address line 1"}
            />
            <TextBox2
              label={"Address Line 2"}
              name={"line2"}
              credentials={address}
              value={address.line2}
              setValue={setAddress}
              placeholder={"Address line 2"}
            />
          </div>

          <div className="flex items-center gap-x-7 mobile:flex-col">
            <TextBox2
              label={"City"}
              name={"city"}
              credentials={address}
              value={address.city}
              setValue={setAddress}
              placeholder={"City name"}
            />
            <TextBox2
              label={"State"}
              name={"state"}
              credentials={address}
              value={address.state}
              setValue={setAddress}
              placeholder={"State"}
            />
            <TextBox2
              label={"Zip Code"}
              name={"zipCode"}
              credentials={address}
              value={address.zipCode}
              setValue={setAddress}
              placeholder={"Zip code"}
            />
          </div>
          <TextBox2
            label={"Listing Title"}
            name={"listingTitle"}
            credentials={credentials}
            value={credentials.listingTitle}
            setValue={setCredentials}
          />

          <div className="flex justify-between gap-[20px]">
            <div className="w-full text-left my-2">
              <label
                className="text-base1 font-normal block mb-1"
                htmlFor="time"
              >
                <p className="font-segoe font-semibold text-[18px]">Check In</p>
              </label>
              <DatePicker
                selected={checkInTime}
                onChange={handleTimeChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className={`w-full bg-lightgrey p-[10px] outline-none cursor-pointer rounded-xl ${
                  checkinerror && "border-red-500"
                }`}
              />
            </div>

            <div className="w-full text-left my-2">
              <label
                className="text-base1 font-normal block mb-1"
                htmlFor="time"
              >
                <p className="font-segoe font-semibold text-[18px]">
                  Check Out
                </p>
              </label>
              <DatePicker
                selected={checkOutTime}
                onChange={handleTimeChange2}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className={`w-full bg-lightgrey p-[10px] outline-none cursor-pointer rounded-xl ${
                  checkerror && "bg-red-500"
                }`}
              />
            </div>
          </div>

          <div className="w-full text-left mt-7 mobile:mt-2">
            <label className="text-base1 font-normal block mb-1" for="message">
              Entry Instructions
            </label>
            <textarea
              className="w-full bg-lightgrey rounded-xl p-2 outline-none"
              id="message"
              rows="5"
              placeholder="Use the Instructions to better help our driver i.e. (Upon arrival at the rental property, locate the lockbox near the entrance. The provided code is 1234. Please remember to return the key to the lockbox and scramble the numbers for security.)"
              name="description"
              value={credentials.description}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  [e.target.name]: e.target.value,
                })
              }
            ></textarea>
          </div>

          {/* <div className="w-full text-left mt-7 mobile:mt-2">
            <label className="text-base1 font-normal block mb-1" for="keyInfo">
              Description
            </label>
            <textarea
              className="w-full bg-lightgrey rounded-xl p-2 outline-none"
              id="keyLocation"
              rows="7"
              placeholder="Use the Instructions to better help our driver i.e. (Upon arrival at the rental property, locate the lockbox near the entrance. The provided code is 1234. Please remember to return the key to the lockbox and scramble the numbers for security.)"
              name="keyInfo"
              value={credentials.keyInfo}
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  [e.target.name]: e.target.value,
                })
              }
            ></textarea>
          </div> */}

          {/* For Mobile Screen */}
          <button
            onClick={addPropertyHandler}
            className="bg-[#00B227] text-white font-bold py-2 px-4 rounded-lg mobile:block hidden m-auto mt-4"
          >
            Save
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddProperty;
