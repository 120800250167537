import React from 'react'
import { Link } from 'react-router-dom'
import failedIcon from '../icons/failed.png'


const EmailVerificationFailed = ({error}) => {
    return (
        <>

            <div className="w-[90%] m-auto shadow-4xl h-[500px] tablet:h-[400px] bg-white my-7 overflow-auto flex flex-col justify-around items-center p-20 mobile:p-5 ">
                <h1 className='text-lg mobile:text-base3 text-black p-5 text-center'>{error}</h1>
                <img src={failedIcon} className='w-10' alt="failed icon" />

                {/* <h2>Please Verify with the original link</h2> */}

                <p className="text-base2 text-light ">
                    Go Back to Login {" "}
                    <label className=" text-primary">
                        <Link to={"/login"}>Login</Link>
                    </label>
                </p>

            </div>
        </>
    )
}

export default EmailVerificationFailed