import React, { useEffect } from "react";
import HostNav from "../components/hostComponents/HostNav";
import ProfileComponent from "../components/hostComponents/ProfileComponent";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { profileUpdateReset } from "../store/slices/hostSlices";
import { getFlags, getHostProfile } from "../store/thunks/hostActions";
import { Alert } from "@mui/material";

function ProfileSettingScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { Info } = useSelector((state) => state.hostDetails);

  const { hostInfo: hostLogin } = useSelector((state) => state.host);

  const {
    success: successUpdate,
    message: messageUpdate,
    error: errorUpdate,
  } = useSelector((state) => state.hostUpdate);

  useEffect(() => {
    if (!hostLogin) {
      navigate("/login");
    }
    if (successUpdate || hostLogin._id !== params.hid) {
      dispatch(getHostProfile(hostLogin._id));
    }

    dispatch(getHostProfile(hostLogin._id));
  }, [navigate, hostLogin, successUpdate, dispatch, params.hid]);

  useEffect(() => {
    if (successUpdate || errorUpdate) {
      dispatch(getHostProfile(hostLogin._id));

      setTimeout(() => {
        dispatch(profileUpdateReset());
      }, 2000);
    }
  }, [successUpdate, errorUpdate, dispatch]);

  useEffect(() => {
    dispatch(getFlags(hostLogin._id));
  }, []);
  return (
    <>
      {/* {loadingUpdate && <div id="loading" className="w-full h-screen absolute bg-black opacity-80"></div>}
      {loadingDetails && <div id="loading" className="w-full h-screen absolute bg-black opacity-80"></div>} */}

      {/* //overflow auto to attain sticky position */}
      <div className="flex w-full h-screen overflow-auto">
        {/* LEFT */}
        {/* <SideNav />  */}

        {/* RIGHT  */}
        <div className="flex flex-col w-full mobile:w-full px-10 tablet:px-0 mx-auto">
          <HostNav edit={true} />

          {errorUpdate && (
            <Alert severity="error">
              <strong>{errorUpdate}</strong>
            </Alert>
          )}

          {successUpdate && (
            <Alert severity="success">
              <strong>{messageUpdate}</strong>
            </Alert>
          )}

          <ProfileComponent
            Info={Info}
            errorUpdate={errorUpdate}
            successUpdate={successUpdate}
          />
        </div>
      </div>
    </>
  );
}

export default ProfileSettingScreen;
