import React, { useState, useEffect } from "react";
import SideNavLink from "./SideNavLink";
import setting from "../../icons/setting.svg";
import support from "../../icons/Support.svg";
import logoutLogo from "../../icons/logout.svg";
import logo from "../../icons/logo.jpeg";
import home from "../../icons/home.svg";
import cross from "../../icons/cross.png";
import earnings from "../../icons/earnings.png";
import messageIcon from "../../icons/messageCentreVector.svg";
import { useDispatch } from "react-redux";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import {
  hostEarningsReset,
  hostPendingEarningsReset,
  hostProfileReset,
  hostPropertiesReset,
  hostPropertyDetailsReset,
  logout,
} from "../../store/slices/hostSlices";
import { useSelector } from "react-redux";
import { setBlurOff, setNavOff } from "../../store/slices/hostSlices";
import CsvExport from "./CsvExport";

const SideNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { hostInfo: hostLogin } = useSelector((state) => state.host);
  const {automationAllowed} = useSelector((state) => state.hostFlags);
  const { hostSideNav } = useSelector((state) => {
    return state;
  });
  // OnBoarded check
  const isOnBoarded = useSelector((state) => {
    return state.hostDetails.Info?.isOnBoarded;
  });

  const [laptopView, setLaptopView] = useState();

  const onClickCross = () => {
    dispatch(setNavOff());
    dispatch(setBlurOff());
  };

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      dispatch(hostEarningsReset());
      dispatch(hostPropertiesReset());
      dispatch(hostPropertyDetailsReset());
      dispatch(hostPendingEarningsReset());
      dispatch(hostProfileReset());
      dispatch(logout());
      navigate("/login");
    }
  };

  const handleResize = () => {
    if (window.innerWidth > 1024) {
      setLaptopView(true);
    } else {
      setLaptopView(false);
      dispatch(setNavOff());
      dispatch(setBlurOff());
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth]);
  return (
    <>
      {(hostSideNav || laptopView) && (
        <div
          id="navLeft"
          className="flex-col items-center w-[300px] bg-white shadow-5xl laptop:sticky laptop:flex top-0 tablet:rounded-r-[50px] tablet:z-20 tablet:absolute tablet:h-screen mobile:w-[80%] min-h-[650px]"
        >
          <div className="absolute cross-btn text-right top-5 right-5 laptop:hidden">
            <img
              id="cross"
              src={cross}
              onClick={onClickCross}
              className="cursor-pointer w-[40px]"
              alt=""
            />
          </div>

          {/* Logo */}
          <div className="flex justify-center items-center brand h-[160px] w-[90%] m-auto mobile:mt-2">
            <NavLink to="/host" className="w-[70%]">
              <img
                className="w-full"
                src={logo}
                alt=""
                onClick={() => {
                  dispatch(setNavOff());
                  dispatch(setBlurOff());
                }}
              />
            </NavLink>
          </div>

          {/* Mobile Only Buttin <Add Property> */}
          <div className="laptop:hidden w-full my-5 border-y-2 border-lightborder p-5 text-center m-auto">
            <NavLink
              to="/addProperty"
              className={`${!isOnBoarded && "pointer-events-none"}`}
            >
              <button
                className="bg-black text-white font-bold px-4 rounded-lg w-full h-[60px] laptop:hidden"
                onClick={() => {
                  dispatch(setNavOff());
                  dispatch(setBlurOff());
                }}
              >
                Add property
              </button>
            </NavLink>
            <CsvExport className="w-full" />
          </div>

          {/* Navigation Links */}
          <div className="nav flex-grow w-full">
            <ul className="flex flex-col gap-2 justify-center items-center mt-5">
              <SideNavLink
                icon={home}
                label={"Home"}
                link={"host"}
                active="activeNav"
              />

              <SideNavLink
                icon={earnings}
                label={"Earnings"}
                link={`hostdashboard`}
              />

              <SideNavLink
                icon={setting}
                label={"Profile Settings"}
                link={`profileSetting/${hostLogin._id}`}
              />
              <SideNavLink icon={support} label={"Support"} link={"support"} />
              
              {automationAllowed && (
                <SideNavLink
                  icon={messageIcon}
                  label={"Message Centre"}
                  link={"messageCentre"}
                />
              )}
            </ul>
          </div>

          {/* Logout Button */}
          <div
            className="flex justify-center border-t-2 border-lightborder w-full m-auto bottom-5 absolute"
            onClick={handleLogout}
          >
            <SideNavLink
              icon={logoutLogo}
              label={"Logout"}
              link={location.pathname.slice(1)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SideNav;
