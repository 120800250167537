import React, { useState, useRef, useEffect } from "react";
import edit from "../../icons/Edit.svg";
import copy from "../../icons/Copy.svg";
import more from "../../icons/more.png";
import key from "../../icons/key.png";
import newedit from "../../icons/newedit.png";

import TripleDotComponent from "./TripleDotComponent";
import { useNavigate } from "react-router-dom";
import { updateHostProperty } from "../../store/thunks/hostActions";
import { useDispatch } from "react-redux";
import { fabClasses } from "@mui/material";


const Property = ({ property,data }) => {

  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [threeDotBox, setThreeDotBox] = useState(false);
  const [copyText, setCopyText] = useState("Property URL");
  const [copyDescriptionText, setCopyDescriptionText] = useState("Message");
  const [editMode, setEditMode] = useState(false);
  const [description, setDescription] = useState("");

  const handleKeyUpdate = () => {
    setEditMode(false);
    navigate("/host");
    const propertyCredentials = { description };
    dispatch(updateHostProperty({ pid: property._id, propertyCredentials }));
  };

  // Functionality For Mobile Screens Only
  const handleMore = (e) => {
    setThreeDotBox(!threeDotBox);
    e.stopPropagation();
  };

  function unsecuredCopyToClipboard(text, description) {
    const textArea = document.createElement("textarea");
    textArea.value = description + " " + text; // Attach the description text before the link
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  }

  // const copyToClipboard = (content) => {
  //   if (window.isSecureContext && navigator.clipboard) {
  //     navigator.clipboard.writeText(content);
  //   } else {
  //     unsecuredCopyToClipboard(content);
  //   }
  // };

  useEffect(() => {
    if (property) {
      setDescription(property.description ? property.description : "");
    }
  }, [property]);

const [comission,setCommission] = useState(0)


useEffect(()=>{
  data?.map((entry) => {
    if (entry._id === property._id) {
      setCommission(entry.totalCommission.toFixed(2));
    }
    return null;
  })
},[data])


  return (
    <>
      {threeDotBox && (
        <div
          onClick={() => {
            setThreeDotBox(false);
          }}
          className={`absolute opacity-40 top-0 left-0 h-screen w-full bg-black z-10`}
        ></div>
      )}

      {/* ------------------------------------------------------------------------------------ */}
      <div
        className={`flex items-center justify-between shadow-4xl bg-white my-3 rounded-xl w-full p-3 tablet:p-5 laptop:p-5 mobile:mx-0 gap-x-5 cursor-pointer relative overflow-auto mobile:overflow-visible`}
        onClick={() =>
          !editMode && navigate(`/host/propertyDetails/${property._id}`)
        }
      >
        <div className="w-[50%] flex gap-2">
          <div className="w-[50%]">
            <div className="flex gap-2 items-center">
            <h5 className="font-segoe text-light text-sm mobile:text-[13px]">
              Entry Instructions
            </h5>
            



            <div className="mt-2">
             {!editMode ? (
          <div
            className="w-[100px] min-w-[40px] flex mobile:hidden cursor-pointer h-10 items-center justify-center hover:scale-[120%]"
            onClick={(e) => {
              setEditMode(true);
              inputRef.current.focus();
              e.stopPropagation();
            }}
          >
            <div>
              <img
                className="w-[18px] mb-[10px]"
                src={newedit}
                alt="Edit"
              />
            </div>
          </div>
        ) : (
          <button
            onClick={() => {
              handleKeyUpdate();
              setEditMode(false);
            }}
            className="w-[50px] bg-[#00B227] text-[10px] text-white font-bold py-2 px-2 rounded-lg mobile:block cursor-pointer"
          >
            Update
          </button>
        )} 
</div>





            </div>
            {/* <p className="text-base1 text-lightblack font-segoe font-semibold truncate">
            {property.description}
          </p> */}

            <div className="flex gap-2">
              <input
                ref={inputRef}
                name="KeyLocation"
                type="text"
                // value={keyLocation}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={`mobile:text-[11px]  w-full truncate bg-inherit py-1 px-1 p-[10px] outline-none font-semibold cursor-pointer ${
                  editMode &&
                  "border-b-[1px] border-light text-base1 text-lightblack font-segoe"
                }`}
                readOnly={!editMode}
              />





             {/* Key Btn */}
        {/* <div>
             {!editMode ? (
          <div
            className="w-[100px] min-w-[40px] flex mobile:hidden cursor-pointer h-10 items-center justify-center hover:scale-[120%]"
            onClick={(e) => {
              setEditMode(true);
              inputRef.current.focus();
              e.stopPropagation();
            }}
          >
            <div>
              <img
                className="w-[24px] mb-[10px]"
                src={edit}
                alt="Edit"
              />
            </div>
          </div>
        ) : (
          <button
            onClick={() => {
              handleKeyUpdate();
              setEditMode(false);
            }}
            className="w-[100px] bg-[#00B227] text-white font-bold py-2 px-2 rounded-lg mobile:block cursor-pointer"
          >
            Update
          </button>
        )} 
</div> */}



              
            </div>
          </div>





          <div className="w-[50%] mt-3">
            <h5 className="font-segoe text-light text-sm">Address</h5>
            <p className="text-base1 mobile:text-[11px] text-lightblack font-segoe font-semibold py-1 px-1 truncate">
              {property.address.line1}{" "}
              {property.address.line2 ? property.address.line2 : ""},{" "}
              {property.address.city}, {property.address.state}{" "}
              {property.address.zipCode}
            </p>
          </div>
        </div>


        <div className="">

 
        <div>
          <h5 className="font-segoe text-light text-sm mb-2" >Property Earnings</h5>
          <p style={{color:'green',textAlign:'center'}}> <span className="font-bold">$</span>{comission}</p>
        </div>


</div>

        

        {/* <p>copy unique link</p> */}
        <button
          className="bg-lightgrey rounded-md mobile:hidden"
          onClick={(e) => {
            // navigator.clipboard.writeText(property.uniqueLink);
            // copyToClipboard(property.uniqueLink)
            const description = "";
            unsecuredCopyToClipboard(property.uniqueLink, description);

            // alert(property.uniqueLink);
            setCopyText("Copied!");
            setTimeout(() => {
              setCopyText("Property URL");
            }, 3000);
            e.stopPropagation();
            // Message Component
          }}
        >
          <div className="flex items-center gap-4 p-[8px_12px] tablet:p-2 text-sm tablet:gap-2 min-w-[120px]">
            <p
              className={`text-light font-segoe font-normal text-[13px] ${
                copyText === "Copied!" && "text-primary"
              }`}
            >
              {copyText}
            </p>
            <img
              src={copy}
              alt=""
            />
          </div>
        </button>

        {/* <p>copy description </p> */}
        <button
          className="bg-lightgrey rounded-md mobile:hidden"
          onClick={(e) => {
            // navigator.clipboard.writeText(property.uniqueLink);
            // copyToClipboard(property.uniqueLink)
            const description = `I'm always looking for ways to elevate your stay, and I think you'll love this! We've partnered with Bellhopt to offer affordable pre-arrival grocery delivery.
            No more initial store runs; let's get your vacation started on the right foot! The best part? No need to create an account.
            Simply order directly to your rental here: `;
            unsecuredCopyToClipboard(property.uniqueLink, description);
            // alert(property.uniqueLink);
            setCopyDescriptionText("Copied!");
            setTimeout(() => {
              setCopyDescriptionText("Message");
            }, 3000);
            e.stopPropagation();
            // Message Component
          }}
        >
          <div className="flex items-center gap-4 p-[8px_12px] tablet:p-2 text-sm tablet:gap-2 min-w-[120px]">
            <p
              className={`text-light font-segoe font-normal ${
                copyDescriptionText === "Copied!" && "text-primary"
              }`}
            >
              {copyDescriptionText}
            </p>
            <img
              src={copy}
              alt=""
            />
          </div>
        </button>

{/* 

        <div className="flex mobile:hidden cursor-pointer min-w-[40px]">
                <div
                  onClick={(e) => {
                    navigate(`propertyDetails/${property._id}?edit=true`);
                    e.stopPropagation();
                  }}
                >
                  <img
                  width={24}
    
                    src={edit}
                    alt="Edit"
                  />
                </div>
              </div> */}




        {/* Three Dot Icon */}



        <div
          className="hidden mobile:flex h-[50px] w-[50px] rounded-[50%] bg-white relative z-200"
          onClick={(e) => {
            handleMore(e);
          }}
        >
          <img
            src={more}
            className={`w-[20px] m-auto pointer-events-none`}
            alt=""
          />
          {threeDotBox && (
            <TripleDotComponent
              fun={unsecuredCopyToClipboard}
              propertyId={property._id}
              ulink={property.uniqueLink}
              keyInfo={property.description}
              setCopyText={setCopyText}
              setThreeDotBox={setThreeDotBox}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Property;
