import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = () => {

    const { hostInfo } = useSelector((state) => state.host)
    return (
        hostInfo ? <Outlet /> : <Navigate to="/login" />
    )
}

export default PrivateRoutes