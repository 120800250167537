import React from "react";
import copy from "../../icons/Copy.svg";
import edit from "../../icons/Edit.svg";
import del from "../../icons/Delete.svg";
import { Link } from "react-router-dom";

const TripleDotComponent = ({
  propertyId,
  ulink,
  keyInfo,
  setThreeDotBox,
  fun,
}) => {
  return (
    <div
      id="tripleDotBox"
      className="w-[160px] h-fit p-[10px] rounded-lg flex-col justify-between bg-white absolute right-0 top-10 z-10"
    >
      <Link
        onClick={(e) => {
          e.stopPropagation();

          navigator.clipboard.writeText(ulink);

          setThreeDotBox(false);
          // navigator.clipboard.writeText(ulink);
          alert(ulink);
        }}
        className="btn w-full flex m-auto gap-3 bg-lightgrey p-2 my-1 justify-center rounded-md"
      >
        <p>Property URL</p>
        <img
          src={copy}
          alt=""
          className="w-[15px]"
        />
      </Link>
      <Link
        onClick={(e) => {
          e.stopPropagation();

          const description =
            "I also offer a grocery delivery and stocking service through Bellhopt for my guests to make grocery shopping less stressful and time-consuming, especially in a new place. They deliver fresh, high-quality products to your rental before you even arrive. If you’d like to order you can order directly to the rental Order Link: ";
          fun(ulink, description);
          // alert(keyInfo);
          setThreeDotBox(false);
        }}
        className="btn w-full flex m-auto gap-3 bg-lightgrey p-2 my-1 justify-center rounded-md"
      >
        <p>Message</p>
        <img
          src={copy}
          alt=""
          className="w-[15px]"
        />
      </Link>
      <Link
        to={`/host/propertyDetails/${propertyId}?edit=true`}
        className="btn w-full flex m-auto gap-3 bg-lightgrey p-2 my-1 justify-center rounded-md"
      >
        <p>Edit</p>
        <img
          src={edit}
          alt=""
          className="w-[15px]"
        />
      </Link>
      {/* <Link className="btn w-full flex m-auto gap-1 bg-lightgrey p-2 my-1 justify-between rounded-md"
        onClick={handleDeleteBtn}>
        <p>Delete</p>
        <img src={del} alt="" className="w-[15px]" />
      </Link> */}
    </div>
  );
};

export default TripleDotComponent;
