import React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import DriverEmailVerificationFailed from '../components/driver/DriverEmailVerificationFailed';
import DriverEmailVerificationSuccess from '../components/driver/DriverEmailVerificationSuccess';
import Header from '../components/Header';
import successIcon from '../icons/success.svg'


const DriverStripeRedirectScreen = () => {


    return (
        <div className='w-full h-screen'>

            <Header />
            <div className="w-[90%] m-auto shadow-4xl h-[500px] tablet:h-[300px] bg-white my-7 overflow-auto flex flex-col justify-around items-center mobile:p-5 borde">
                <h1 className='text-base2 font-bold py-2 px-4'>Stripe On-Boarding Successful</h1>
                <img src={successIcon} className='w-20' alt="success icon" />
                <h2 className='text-base1 text-center'>Your stripe account on-boarding was successful.</h2>
                <h2 className='text-center font-semibold'>Visit the mobile app to Login</h2>
            </div>

            {/* <DriverEmailVerificationSuccess /> */}

        </div>
    )
}

export default DriverStripeRedirectScreen