import React, { useState } from 'react'
import HostNav from '../components/hostComponents/HostNav'
import { useNavigate } from 'react-router-dom'
import back from '../icons/leftArrow.png'
import searchIcon from '../icons/search.png'
import { getAllOrders } from '../store/thunks/hostActions'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OrderTable from '../components/hostComponents/OrderTable'
import { CircularProgress } from '@mui/material'

const OrdersScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { loading, orders, error } = useSelector((state) => state.orders)
    const { hostInfo: { _id: hid } } = useSelector((state) => state.host)

    // make a useState for search and render only filtered orders
    const [search, setSearch] = useState('')
    const filteredorders = orders && orders.filter((order) => order._id.toLowerCase().includes(search.toLowerCase())
        || order.status.toLowerCase().includes(search.toLowerCase()) || `${order?.property?.address.line1} ${order?.property?.address.line2 ? order?.property?.address.line2 : ''} ${order?.property?.address.city} ${order?.property?.address.state}, United States`.toLowerCase().includes(search.toLowerCase()))

    // call a use effect to dispatch get all orders
    useEffect(() => {
        dispatch(getAllOrders(hid))
    }, [])

    return (
        <div className="flex w-full h-screen overflow-auto">
            <div className="flex flex-col w-full mobile:w-full px-10 tablet:px-0 mx-auto">
                <HostNav hide={true} />

                {/* <div id="shadow-box" className="m-auto shadow-4xl pb-[30px] bg-white my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 overflow-scroll scrollbar-thin"> */}
                <div id="shadow-box" className="m-auto shadow-4xl pb-[30px] bg-white my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 overflow-aut0 scrollbar-thin">
                    <div className="w-full m-auto">
                        <div className="flex justify-between items-center px-5 py-3 w-full relative">
                            <h1 className="text-base3 font-segoe font-semibold mobile:text-base2 flex items-center gap-x-2">
                                <img src={back} alt="back" className="w-[30px] h-[20px] inline-block pr-2 cursor-pointer" onClick={() => navigate(-1)} />
                                <label>Orders</label>
                            </h1>

                            <div className="relative">
                                <button
                                    id="searchBtn"
                                    className="w-[20px] absolute top-0 bottom-0 left-3"
                                >
                                    <img className="w-full" src={searchIcon} alt="" />
                                </button>
                                <input
                                    onChange={(e) => setSearch(e.target.value)}
                                    type="text"
                                    placeholder="Search Orders here"
                                    className="w-[400px] tablet:max-w-[260px] mobile:w-[170px] h-[30px] bg-lightgrey rounded-lg p-[20px] pl-[40px] outline-none"
                                />
                            </div>
                        </div>

                        {!loading && orders &&
                            <OrderTable orders={filteredorders} />
                        }
                        {loading &&
                            <div className="w-full h-[400px] flex justify-center items-center">
                                <CircularProgress size={40} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

export default OrdersScreen