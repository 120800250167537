import React, { useState, useEffect } from "react";
import TextBox2 from "../TextBox2";
import edit from "../../icons/Edit.svg";
import back from "../../icons/left.png";
import { useDispatch } from "react-redux";
import { updateHostProfile } from "../../store/thunks/hostActions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

function ProfileComponent({ Info }) {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { hid } = params;

  const [editMode, setEditMode] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);

  const {
    loading: loadingUpdate,
    success: successUpdate,
    error: errorUpdate,
  } = useSelector((state) => state.hostUpdate);

  const [address, setAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const [credentials, setCredentials] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  

  const handleUpdate = () => {
    const profileCredentials = { address, ...credentials };
    dispatch(updateHostProfile({ profileCredentials, hid }));
  };

  useEffect(() => {
    if (errorUpdate) {
      setEditMode(true);
      setIsReadOnly(false);
    } else if (successUpdate) {
      console.log("false");
      setEditMode(false);
      setIsReadOnly(true);
    }
    if (Info) {
      setAddress({ ...Info.address });
      setCredentials({
        firstName: Info.firstName,
        lastName: Info.lastName,
        email: Info.email,
        phoneNumber: Info.phoneNumber,
        password: ""
      });
    }
  }, [Info, dispatch, errorUpdate, successUpdate]);

  return (
    <>
      <div
        id="shadow-box"
        className="m-auto shadow-4xl pb-[30px] bg-white my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 overflow-scroll scrollbar-thin"
      >
        <div className="w-full m-auto">
          <div className="flex justify-between items-center px-5 py-3 border-b-lightborder border-b-2 w-full relative">
            <h1 className="text-base3 font-segoe font-semibold mobile:text-base2 flex items-center gap-x-2">
              <img
                src={back}
                alt="back"
                className="w-[30px] h-[20px] inline-block pr-2 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <label>Profile Settings </label>
            </h1>

            {/* Profile Edit Button */}
            <div className="flex items-center">
              {loadingUpdate && (
                <CircularProgress
                  color="inherit"
                  className="mr-2"
                  size={"20px"}
                />
              )}
              {!editMode ? (
                <button
                  id="btn"
                  className="bg-lightgrey flex justify-center items-center rounded-lg mobile:rounded-[50%] cursor-pointer"
                  onClick={() => {
                    setEditMode(true);
                    setIsReadOnly(false);
                  }}
                >
                  <p className="text-light font-segoe font-normal p-2 mobile:hidden">
                    Edit Profile
                  </p>
                  <img
                    src={edit}
                    className="w-[40px] p-3"
                    alt=""
                  />
                </button>
              ) : (
                <button
                  onClick={() => {
                    handleUpdate();
                  }}
                  className="bg-[#00B227] text-white font-bold py-2 px-4 rounded-lg mobile:block "
                >
                  Update
                </button>
              )}
            </div>
          </div>

          <div className={`w-[95%] m-auto form pt-5`}>
            <div className="flex items-center gap-x-7 mobile:flex-col">
              <TextBox2
                label={"First Name"}
                name={"firstName"}
                type={"text"}
                credentials={credentials}
                value={credentials.firstName}
                setValue={setCredentials}
                readOnly={isReadOnly}
              />
              <TextBox2
                label={"Last Name"}
                name={"lastName"}
                type={"text"}
                credentials={credentials}
                value={credentials.lastName}
                setValue={setCredentials}
                readOnly={isReadOnly}
              />
            </div>
            <div className="flex items-center gap-x-7 mobile:flex-col mb-6">
              <div className="w-full text-left my-2 relative">
                <TextBox2
                  label={"Email"}
                  name={"email"}
                  type={"email"}
                  credentials={credentials}
                  value={credentials.email}
                  setValue={setCredentials}
                  readOnly={true}
                  disabled={editMode}
                />
                <Link to={`/profileSetting/${hid}/change-email`} className="text-deleteRed underline absolute"> Change Email</Link>

              </div>
              <TextBox2
                label={"Phone"}
                name={"phoneNumber"}
                type={"tel"}
                credentials={credentials}
                value={credentials.phoneNumber}
                setValue={setCredentials}
                readOnly={isReadOnly}
              />
            </div>

            <div className="w-[49%] mobile:w-full">
              <TextBox2
                label={"Company Name"}
                name={"companyName"}
                type={"text"}
                credentials={credentials}
                // value={address.line1}
                readOnly={isReadOnly}
              />
            </div>
            <div className="w-[49%] mobile:w-full">
              <TextBox2
                label={"Password"}
                name={"password"}
                type={"text"}
                credentials={credentials}
                value={"*************"}
                setValue={setCredentials}
                readOnly={true}
              />
              <div>
                <Link to={`/profileSetting/${hid}/reset-password`} className="text-deleteRed underline"> Change Password</Link>
              </div>
            </div>

            

            <div className="flex items-center gap-x-7 mobile:flex-col">
              <TextBox2
                label={"Address Line 1"}
                name={"line1"}
                type={"text"}
                credentials={address}
                value={address.line1}
                setValue={setAddress}
                readOnly={isReadOnly}
              />

              <TextBox2
                label={"Address Line 2"}
                name={"line2"}
                type={"text"}
                credentials={address}
                value={address.line2}
                setValue={setAddress}
                readOnly={isReadOnly}
              />
            </div>
            <div className="flex items-center gap-x-7 mobile:flex-col">
              <TextBox2
                label={"City"}
                name={"city"}
                type={"text"}
                credentials={address}
                value={address.city}
                setValue={setAddress}
                readOnly={isReadOnly}
              />
              <TextBox2
                label={"State"}
                name={"state"}
                type={"text"}
                credentials={address}
                value={address.state}
                setValue={setAddress}
                readOnly={isReadOnly}
              />
              <TextBox2
                label={"Zip Code"}
                name={"zipCode"}
                type={"text"}
                credentials={address}
                value={address.zipCode}
                setValue={setAddress}
                readOnly={isReadOnly}
              />


            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileComponent;
