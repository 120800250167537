import React from "react";
import Header from "../components/Header";
import TextBox from "../components/TextBox";
import { useState, useEffect } from "react";
import { hostSignup } from "../store/thunks/hostActions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { registrationSchema } from "../schemas";
import { hostSignupReset } from "../store/slices/hostSlices";
import ReCAPTCHA from "react-google-recaptcha";

const SignupScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [signupError, setSignupError] = useState();
  const [captchaValue, setCaptchaValue] = useState(null); // State to hold CAPTCHA response
     // Function to handle CAPTCHA value change
     const onCaptchaChange = (token) => {
      setCaptchaValue(token); // Set the CAPTCHA response token
    };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    isManager: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      zipCode: "",
    },
  };

  const { values, errors, touched, handleBlur, handleChange, getIn, form } =
    useFormik({
      initialValues: initialValues,
      validationSchema: registrationSchema,
    });

  const { loading, success, error } = useSelector((state) => {
    return state.hostSignUp;
  });
  const { hostInfo } = useSelector((state) => {
    return state.host;
  });

  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyName, setCompanyName] = useState(null);

  const handleSignUp = () => {
    if (!captchaValue) {
      setSignupError("Please verify that you are not a robot.");
      return;
    }
  

    if (values.isManager) {
      values.company = companyName;
    }

    if (errors.address && Object.keys(errors.address).length > 0) {
      console.log("errors", errors.address);
      setSignupError(errors.address[Object.keys(errors.address)[0]]);

      return;
    }

    if (Object.keys(errors).length > 0) {
      console.log("errors", errors.address);
      setSignupError(errors[Object.keys(errors)[0]]);

      return;
    }

    if (Object.keys(errors).length === 0 && values.password === confirmPassword && captchaValue ) {
      // Include the captchaValue when dispatching
      // console.log(captchaValue)
      dispatch(hostSignup({ ...values, captchaResponse: captchaValue }));
      // console.log(captchaValue)
    }
    
  };

  useEffect(() => {
    if (hostInfo) {
      navigate("/host");
    }
    if (success) {
      navigate("/please-verify");
      dispatch(hostSignupReset());
    }
    if (error) {
      setTimeout(() => {
        dispatch(hostSignupReset());
      }, 2000);
    }
  }, [success, navigate, hostInfo, error]);

  useEffect(() => {
    setTimeout(() => {
      signupError && setSignupError(null);
    }, 3000);
  }, [signupError]);


  return (
    <>
      <div className="flex flex-col min-h-screen w-full h-fit">
        <Header />
        {error && (
          <Alert
            severity="error"
            style={{
              position: "sticky",
              width: "90%",
              margin: "auto",
              top: 10,
              left: 0,
              right: 0,
            }}
          >
            <strong>{error}</strong>
          </Alert>
        )}
        {signupError && (
          <Alert
            severity="error"
            style={{
              position: "sticky",
              width: "90%",
              margin: "auto",
              top: 10,
              left: 0,
              right: 0,
            }}
          >
            {signupError}
          </Alert>
        )}

        <div className="flex justify-center bg-white h-full w-full flex-grow">
          <div className="laptop:w-[450px] tabletOnly:w-[400px] mobile:w-[320px] text-center pb-10">
            {/* TOP */}
            <div className="top mt-5">
              <h2 className="font-bold text-lg">Sign Up</h2>
              <p className="text-base2 text-light">
                Already have an account?{" "}
                <label className=" text-primary">
                  <Link to={"/login"}>Sign In</Link>
                </label>
              </p>
            </div>

            {/* FORM */}

            <div className="form mt-[50px]">
              <div className="flex items-center gap-x-2">
                <TextBox
                  label={"First Name"}
                  name={"firstName"}
                  value={values.firstName}
                  onChange={handleChange}
                  errors={errors}
                  handleBlur={handleBlur}
                />
                <TextBox
                  label={"Last Name"}
                  name={"lastName"}
                  value={values.lastName}
                  onChange={handleChange}
                  errors={errors}
                  handleBlur={handleBlur}
                />
              </div>

              <div className="py-1">
                <TextBox
                  label={"Email"}
                  name={"email"}
                  value={values.email}
                  onChange={handleChange}
                  errors={errors}
                  handleBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <p className="text-[red]">{errors.email}</p>
                ) : null}
              </div>

              <div className="py-1">
                <TextBox
                  label={"Mobile Number"}
                  name={"phoneNumber"}
                  value={values.phoneNumber}
                  onChange={handleChange}
                  errors={errors}
                  handleBlur={handleBlur}
                />
                {errors.phoneNumber && touched.phoneNumber ? (
                  <p className="text-[red]">{errors.phoneNumber}</p>
                ) : null}
              </div>

              {/* Radio Buttons For isManager state */}

              <div className="flex flex-gap-5 w-full justify-between py-5">
                <label htmlFor="">
                  Are you a Property Manager{" "}
                  {errors.isManager ? (
                    <p className="text-[red] inline-block pl-1">*</p>
                  ) : null}{" "}
                </label>

                <div className="yes_no flex">
                  <div className="mx-5">
                    <input
                      className="mx-2"
                      type="radio"
                      id="mgr"
                      name="isManager"
                      // value={values.isManager}
                      value={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="isManager">Yes</label>
                  </div>
                  <div className="mx-5">
                    <input
                      className="mx-2"
                      type="radio"
                      id="mgr"
                      name="isManager"
                      // value={values.isManager}
                      value={false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="isManager">No</label>
                  </div>
                </div>
              </div>

              {values.isManager === "true" && (
                <div className="w-full mobile:w-full">
                  <TextBox
                    label={"Company Name"}
                    name={"companyName"}
                    type={"text"}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
              )}

              <div className="flex items-center gap-x-7 mobile:flex-col">
                <TextBox
                  label={"Address Line 1"}
                  name={"address.line1"}
                  type={"text"}
                  value={values.address.line1}
                  onChange={handleChange}
                  errors={errors}
                  getIn={getIn}
                  handleBlur={handleBlur}
                />

                <TextBox
                  label={"Address Line 2"}
                  name={"address.line2"}
                  type={"text"}
                  value={values.address.line2}
                  onChange={handleChange}
                  errors={errors}
                  getIn={getIn}
                  handleBlur={handleBlur}
                />
              </div>
              <div className="flex items-center gap-x-7 mobile:flex-col">
                <TextBox
                  label={"City"}
                  name={"address.city"}
                  type={"text"}
                  onChange={handleChange}
                  value={values.address.city}
                  errors={errors}
                  getIn={getIn}
                  handleBlur={handleBlur}
                />
                <TextBox
                  label={"State"}
                  name={"address.state"}
                  type={"text"}
                  value={values.address.state}
                  onChange={handleChange}
                  errors={errors}
                  getIn={getIn}
                  handleBlur={handleBlur}
                />
                <TextBox
                  label={"Zip Code"}
                  name={"address.zipCode"}
                  type={"text"}
                  value={values.address.zipCode}
                  onChange={handleChange}
                  errors={errors}
                  getIn={getIn}
                  handleBlur={handleBlur}
                />
              </div>

              <TextBox
                label={"Password"}
                name={"password"}
                type={"password"}
                value={values.password}
                onChange={handleChange}
                errors={errors}
                handleBlur={handleBlur}
              />
              {errors.password && touched.password ? (
                <p className="text-[red]">{errors.password}</p>
              ) : null}
              {/* <ErrorMessage name="email" /> */}

              <TextBox
                label={"Confirm Password"}
                name={"confirmPassword"}
                type={"password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              {values.password !== confirmPassword && (
                <p className="text-[red]">Password does not match</p>
              )}

              {/* This is Captcha Code */}

                <ReCAPTCHA
                          sitekey="6LfjWG8pAAAAAPaJUlEtOre0XeDd4K6I43dsBGTq" 
                          onChange={onCaptchaChange}
                          className="my-4" 
                        />

              <button
                onClick={() => handleSignUp()}
                className=" bg-primary w-full h-[50px] rounded-[40px] mt-10"
              >
                <p className="font-bold text-white text-base2">
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      className="mr-2"
                      size={"20px"}
                    />
                  ) : (
                    "Sign Up"
                  )}
                </p>
              </button>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupScreen;
