import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getAllOrders,
  getHostAccountBalance,
  getHostEarnings,
  getHostPendingEarnings,
} from "../../store/thunks/hostActions";
import { CircularProgress } from "@mui/material";
import request from "../../utils/request";
import { NavLink } from "react-router-dom";
import arrowIcon from "../../icons/viewArrowIcon.svg";

function DashboardComponent() {
  const dispatch = useDispatch();

  const [loadingWithdraw, setLoadingWithdraw] = useState(false);
  const [completedOrders, setCompletedOrders] = useState([]);


  console.log("orders checking ->>>>>" ,completedOrders[0])

  const {
    hostInfo: { _id: hid },
  } = useSelector((state) => state.host);

  // const { loading: loadingPending, orders: completedOrders } = useSelector((state) => state.hostEarnings);
  // const { loading: loadingCompleted, orders: pendingOrders } = useSelector((state) => state.hostPendingEarnings);
  const { balance } = useSelector((state) => state.hostAccountBalance);
  ///get orders from store using useselector
  const { loading, orders, success, error } = useSelector(
    (state) => state.orders
  );







  const [totalEarnings, setTotalEarnings] = useState(0);
  const [pendingEarnings, setPendingEarnings] = useState(0);

  const handleClick = async () => {
    setLoadingWithdraw(true);
    try {
      const { data ,status} = await request.post(`/hosts/withdraw/${hid}`);
      console.log(data);
      if(status==200){
        toast.success('Withdrawal successful! Your balance has been updated.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      dispatch(getHostAccountBalance(hid));
    } catch (err) {
      // console.log(err.message);
      toast.error(err.response.data.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoadingWithdraw(false);
  };

  useEffect(() => {
    dispatch(getHostAccountBalance(hid));
    dispatch(getAllOrders(hid));
  }, []);

  useEffect(() => {
    if (orders.length > 0) {
      const completedOrders = orders.filter(
        (order) => order.status === "completed"
      );
      setCompletedOrders(completedOrders);
      const totalEarnings = completedOrders.reduce(
        (acc, order) => acc + order.hostFee,
        0
      );
      setTotalEarnings(totalEarnings);
      const pendingOrders = orders.filter(
        (order) =>
          order.status === "pending" ||
          order.status === "picked" ||
          order.status === "requested"
      );
      const pendingEarnings = pendingOrders.reduce(
        (acc, order) => acc + order.hostFee,
        0
      );
      setPendingEarnings(pendingEarnings);
    }
  }, [orders]);

  return (
    <div className="h-fit">
      <div className="flex justify-between p-3 py-5 laptop:justify-around shadow-4xl bg-white my-3 rounded-xl w-full py- tablet:p-5 gap-x-1 tablet:flex-col tablet:w-[95%] tablet:m-auto tablet:my-5 ">
        <div className=" border-r-[1px] w-[30%] tablet:w-full tablet:border-b-[1px] border-lightborder tablet:border-r-0 tablet:flex tablet:justify-between tablet:items-center">
          <div>
            <p className=" text-[18px] font-segoe text-[#828282] tablet:text-sm">
              Available Amount
            </p>
            <h1 className="font-segoe font-bold text-[40px] tablet:font-semibold tablet:text-[32px]">
              $ {balance !== null ? balance : "..."}
            </h1>
          </div>
          <div>
            <button
              onClick={handleClick}
              className="bg-[#EF4949] text-white py-[5px] px-2 mobile:p-2 rounded-lg w-fit"
            >
              <p className="font-segoe text-base1 tablet:text-[16px] mobile:p-0 mobile:min-w-[84px]  laptop:min-w-[142px] tabletOnly:min-w-[142px]">
                {loadingWithdraw ? (
                  <CircularProgress
                    color="inherit"
                    className="mr-2"
                    size={"20px"}
                  />
                ) : (
                  <p>
                    Withdraw <label className="mobile:hidden">Balance</label>
                  </p>
                )}
              </p>
            </button>
          </div>
        </div>

        <div className=" border-r-[1px] w-[30%] min-w-[240px] tablet:w-full tablet:border-b-[1px] tablet:border-r-0 border-lightborder tablet:py-2">
          <div className="flex flex-col justify-between h-full">
            <p className=" text-[18px] font-segoe text-[#828282] tablet:text-sm">
              Pending Payments
            </p>
            <h1 className="font-semibold font-segoe text-[30px] tablet:font-semibold tablet:text-[32px]">
              {loading ? (
                <CircularProgress
                  color="inherit"
                  className="mr-2"
                  size={"20px"}
                />
              ) : (
                `$ ${pendingEarnings?.toFixed(2)}`
              )}
            </h1>
          </div>
        </div>

        <div className="w-[30%] min-w-[240px] tablet:w-full tablet:py-2">
          <div className="flex flex-col justify-between h-full">
            <p className=" text-[18px] font-segoe text-[#828282] tablet:text-sm">
              Lifetime Earnings
            </p>
            <h1 className="font-semibold font-segoe text-[30px] tablet:font-semibold tablet:text-[32px]">
              {loading ? (
                <CircularProgress
                  color="inherit"
                  className="mr-2"
                  size={"20px"}
                />
              ) : (
                `$ ${totalEarnings?.toFixed(2)}`
              )}
            </h1>
          </div>
        </div>
      </div>

      <div className="flex justify-between my-4 tablet:w-[95%] tablet:m-auto tablet:my-5">
        <div className="flex items-center">
          <h1 className="text-[24px] font-segoeBold ">Order History</h1>
          <p className="tablet:hidden pl-2 text-base2">
            {" "}
            {`(${completedOrders && completedOrders.length})`}{" "}
          </p>
        </div>
        <NavLink
          to="/orderList"
          className=" py-1 px-4 rounded-[20px] border w-fit"
        >
          View All{" "}
          <img src={arrowIcon} alt="arror-icon" className="pl-2 inline-block" />
        </NavLink>
      </div>

      <div className="relative overflow-x-auto border-[0.5px] border-solid border-[#CCCCCC] rounded-lg tablet:w-[95%] tablet:m-auto h-fit mobile:min-h-[600px] mb-5">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-[#F5F5F5;]">
            <tr className="flex w-full ">
              <th scope="col" className="basis-[20%] px-6 mobile:px-2 py-3">
                Date
              </th>
              {/* <th scope="col" className="px-6 py-3 tablet:hidden">
                                Description
                            </th> */}
              <th scope="col" className="basis-[35%] px-6 py-3 tablet:hidden">
                Property address
              </th>
              <th
                scope="col"
                className="basis-[30%] min-w-[220px] px-6 mobile:px-2 py-3"
              >
                Order #
              </th>
              <th
                scope="col"
                className="basis-[10%] px-4 mobile:px-2 py-3 min-w-[80px]"
              >
                Amount
              </th>
            </tr>
          </thead>
          <tbody className="w-full">
            {completedOrders?.length > 0 ? (
              completedOrders.map((order) => (

                



                <tr
                  key={order._id}
                  className="flex w-full border-b-[#CCCCCC] border-b-[0.5px] dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="basis-[20%] px-6 mobile:px-2 py-4">
                    {order.createdAt.slice(0, 10)}
                  </td>
                  {/* <td className="px-6 py-4 tablet:hidden">
                                        Lorem ipsum description...
                                    </td> */}
                  <td className="basis-[35%] px-6 py-4 tablet:hidden truncate">
  {`${order.property.address.line1}, ${order.property.address.city}, ${order.property.address.state}, ${order.property.address.zipCode}`}
</td>
                  <td className="basis-[30%] min-w-[220px] px-6 mobile:px-2 py-4">
                    {order.uniqueId ? order.uniqueId : order._id}
                  </td>
                  <td className="basis-[10%] px-4 mobile:px-2 py-4 min-w-[80px]">
                    $
                    {(
                      order.hostFee +
                      order.bellhoptFee +
                      order.driverFee +
                      order.groceryFee
                    )?.toFixed(2)}
                  </td>
                </tr>
              ))
            ) : loading ? (
              <>
                {Array.apply(null, Array(10)).map((x, index) => (
                  <div
                    key={index}
                    className="w-full h-fit flex flex-col py-1 px-6"
                  >
                    <div className="bg-f5 h-12 w-full rounded"></div>
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
}

export default DashboardComponent;
