import React from "react";
import ReactModal from "react-modal";
import automationFailedVector from "../icons/automationFailedVector.svg";

const AutomationFailed = ({ showModal, handleCloseModal }) => {
  return (
      <ReactModal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        className="Modal automationModal tablet:w-[70%] w-[50%] max-w-[700px] tablet:h-fit h-fit z-30"
        ariaHideApp={false}
        appElement={document.getElementById("root")}
        overlayClassName="Overlay"
      >
        <div className="flex flex-col p-5 font-segoe">
          <img
            src={automationFailedVector}
            alt="Automation Successful"
            className="m-auto mobile:h-[180px] h-[300px]"
          />
          <h1 className="text-base2 m-aut font-bold text-center">
          Automated Messaging Setup Error
          </h1>
          <p className="text-base1 m-auto text-center text-light mt-5 hidden laptop:block">
            We're sorry, but it seems there was an issue with setting up your
            automated messaging system. Our team is here to assist you in
            resolving this matter promptly. Please don't hesitate to reach out
            to our support team, and we'll work diligently to ensure your
            automated messaging is up and running smoothly as soon as possible.
          </p>

          <button className="bg-primary text-white rounded-[200px] px-5 py-2 m-auto mt-5">Request Assistance</button>
        </div>
      </ReactModal>
  );
};

export default AutomationFailed;
