import request from "../../utils/request";
import { createAsyncThunk } from "@reduxjs/toolkit";

const hostLogin = createAsyncThunk(
  "hostLogin/hostlogin",
  async (loginCredentials, { rejectWithValue }) => {
    try {
      const { data } = await request.post("/hosts/login", loginCredentials, {
        withCredentials: true,
      });

      localStorage.setItem("hostInfo", JSON.stringify(data.user));

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const hostSignup = createAsyncThunk(
  "hostSignup/hostSignup",
  async (payload, { rejectWithValue }) => {
    const { captchaResponse, ...signUpCredentials } = payload;

    console.log(captchaResponse); // Now, this should correctly log the captcha response token

    try {
      const requestBody = {
        ...signUpCredentials, // This now correctly represents the rest of the form data
        captchaResponse, // Assuming your backend expects this key for the captcha token
      };

      const { data } = await request.post("/hosts/register", requestBody, {
        withCredentials: true,
      });

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


const getHostProfile = createAsyncThunk(
  "hostProfile/gethostProfile",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await request.get(`/hosts/${id}`, {
        withCredentials: true,
      });
      data.profilePictureUrl = data.profilePicture;
      const state = getState();
      if(!state.hostDetails.Info){
        // const resp = await fetch(data.profilePicture);
        // const blob = await resp.blob();
        // data.profilePicture = URL.createObjectURL(blob);
        data.profilePicture = "";
        dispatch(getHostProfileImage(data.profilePictureUrl));
      }else {
        data.profilePicture = state.hostDetails.Info.profilePicture;
      }
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const getHostProfileImage = createAsyncThunk(
  "hostProfile/gethostProfileImage",
  async (profilePictureUrl, { rejectWithValue, getState }) => {
    console.log("Called");
    try {
      const state = getState();
      const resp = await fetch(profilePictureUrl ? profilePictureUrl : state.hostDetails.Info.profilePictureUrl);
      const blob = await resp.blob();
      const profilePicture = URL.createObjectURL(blob);
      console.log(profilePicture)
      return profilePicture;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);




const updateHostProfile = createAsyncThunk(
  "hostUpdate/updateHostProfile",
  async (credentials, { rejectWithValue }) => {
    try {
      const { data } = await request.patch(
        `/hosts/${credentials.hid}`,
        credentials.profileCredentials,
        { withCredentials: true }
      );

      console.log("data", data);

      // localStorage.setItem("hostInfo", JSON.stringify(data.hostDetails));

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const hostChangePassword = createAsyncThunk(
  "hostChangePassword/hostChangePassword",
  async (credentials, { rejectWithValue }) => {
    try {
      const { data } = await request.patch(
        `/hosts/change-password/${credentials.hid}`,
        credentials.passwordCredentials,
        { withCredentials: true }
      );

      return data;
    }catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const hostChangeEmail = createAsyncThunk(
  "hostChangeEmail/hostChangeEmail",
  async (credentials, { rejectWithValue }) => {
    try {
      const { data } = await request.patch(
        `/hosts/update-email/${credentials.hid}`,
        credentials.emailCredentials,
        { withCredentials: true }
      );

      return data;
    }catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const getHostProperties = createAsyncThunk(
  "hostProperties/getHostProperties",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/properties/host/${id}`, {
        withCredentials: true,
      });

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const updateHostProperty = createAsyncThunk(
  "hostProperties/updateHostProperty",
  async (credentials, { rejectWithValue }) => {
    try {
      const { data } = await request.patch(
        `/properties/${credentials.pid}`,
        credentials.propertyCredentials,
        { withCredentials: true }
      );

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const getPropertyDetails = createAsyncThunk(
  "propertyDetails/getPropertyDetails",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/properties/${id}`, {
        withCredentials: true,
      });

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const deleteHostProperty = createAsyncThunk(
  "deleteProperty/deletehostProperty",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await request.delete(`/properties/${id}`, {
        withCredentials: true,
      });

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const addProperty = createAsyncThunk(
  "addHostProperty/addProperty",
  async (credentials, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/properties`, credentials, {
        withCredentials: true,
      });

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const hostResetPassword = createAsyncThunk(
  "hostResetPassword/hostResetPassword",
  async (credentials, { rejectWithValue }) => {
    const passwordResetToken = credentials.passwordResetToken;
    const newPassword = credentials.newPassword;
    try {
      const { data } = await request.put(
        `/hosts/resetpassword/${passwordResetToken}`,
        { newPassword },
        {
          withCredentials: true,
        }
      );

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//Implement Get All Property Orders
const getHostEarnings = createAsyncThunk(
  "hostEarnings/getHostEarnings",
  async (hid, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/orders/graph/${hid}?type=host&status=completed`,
        {
          withCredentials: true,
        }
      );

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const getHostPendingEarnings = createAsyncThunk(
  "hostPendingEarnings/getHostPendingEarnings",
  async (hid, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/orders/host/${hid}?status=pending`, {
        withCredentials: true,
      });

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const getHostAccountBalance = createAsyncThunk(
  "hostAccountBalance/getHostAccountBalance",
  async (hid, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/hosts/account-balance/${hid}`, {
        withCredentials: true,
      });

      return data.available[0].amount;
    } catch (err) {
      console.log("thunk error", err);
      return rejectWithValue(err.response.data);
    }
  }
);

const getAllOrders = createAsyncThunk(
  "orders/getAllOrders",
  async (hid, { rejectWithValue }) => {
    try {
      // const { data } = await request.get(`/orders/graph/${hid}?type=host`, {
      const { data } = await request.get(`/orders/host/${hid}`, {
        withCredentials: true,
      });

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const getFlags = createAsyncThunk(
  "hostFlags/getFlags",
  async (hid, { rejectWithValue }) => {
    console.log("called", hid);
    try {
      const { data } = await request.get(`/hosts/automation/${hid}`, {
        withCredentials: true,
      });

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export {
  hostLogin,
  hostSignup,
  getHostProperties,
  updateHostProperty,
  getPropertyDetails,
  deleteHostProperty,
  getHostProfile,
  updateHostProfile,
  addProperty,
  hostResetPassword,
  getHostEarnings,
  getHostPendingEarnings,
  getHostAccountBalance,
  getAllOrders,
  hostChangeEmail,
  hostChangePassword,
  getHostProfileImage,
  getFlags,

};
