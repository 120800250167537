import React, { useEffect, useState } from "react";
import Property from "../components/hostComponents/Property";
import HostNav from "../components/hostComponents/HostNav";
import { useDispatch, useSelector } from "react-redux";
import { getFlags, getHostProperties } from "../store/thunks/hostActions";
import { useNavigate } from "react-router-dom";
import Banner from "../components/utilityComponents/Banner";
import { logout } from "../store/slices/hostSlices";
import { Alert, CircularProgress } from "@mui/material";
import {
  updatePropertyReset,
  deletePropertyReset,
  addPropertyReset,
} from "../store/slices/hostSlices";
import request from "../utils/request";
import onboard from "../icons/onboard.svg";

const HostScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [onBoardLoading, setOnBoardLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [errorOnBorad, setErrorOnBoard] = useState();

  const handleClick = async () => {
    setOnBoardLoading(true);
    try {
      const { data } = await request.get("/stripe/get-onboarding-link");
      window.location.href = data.url;
    } catch (error) {
      setErrorOnBoard(error.response.data.msg);
    }
  };

  // use Selectors
  const { hostInfo } = useSelector((state) => {
    return state.host;
  });

  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await request.get(
          `properties/host/${hostInfo._id}/commission`
        );
        setData(response.data.commission);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    dispatch(getFlags(hostInfo._id));
    fetchData();
  }, []);

  // OnBoarded check
  const isOnBoarded = useSelector((state) => {
    return state.hostDetails.Info?.isOnBoarded;
  });

  const {
    loading: loadingProperties,
    properties,
    error,
  } = useSelector((state) => {
    return state.hostProperties;
  });

  const { success: successUpdate, message: messsageupdate } = useSelector(
    (state) => state.propertyUpdate
  );
  const { success: successDelete, message: messageDelete } = useSelector(
    (state) => state.propertyDelete
  );
  const {
    success: successAddProperty,
    msg: messageAddProperty,
    error: errorAddProperty,
  } = useSelector((state) => state.addProperty);

  useEffect(() => {
    if (successUpdate || successDelete)
      dispatch(getHostProperties(hostInfo._id));
  }, [successUpdate, successDelete]);

  useEffect(()=>{
    dispatch(getHostProperties(hostInfo._id))
  }, [hostInfo])

  useEffect(() => {
    if (successUpdate || successDelete || successAddProperty) {
      setTimeout(() => {
        successUpdate && dispatch(updatePropertyReset());
        successDelete && dispatch(deletePropertyReset());
        successAddProperty && dispatch(addPropertyReset());
      }, 2000);
    }
  }, [successAddProperty, successUpdate, successDelete, dispatch]);

  let propertyList = properties;

  if (search.length !== 0) {
    console.log("search");
    propertyList = propertyList.filter((property) =>
      `${property.address.line1} ${
        property.address.line2 ? property.address.line2 : ""
      }, ${property.address.city}, ${property.address.state} ${
        property.address.zipCode
      }, United States`
        .toLowerCase()
        .includes(search.toLowerCase())
    );
  }

  return (
    <>
      {/* {loading && <div id="loading" className="w-full h-screen absolute bg-black opacity-80"></div>} */}
      {/* //overflow auto to attain sticky position */}
      <div className="flex w-full h-screen overflow-auto min-h-[500px]">
        {/* RIGHT  */}
        {/* <div className="w-full tablet:w-full px-10 tablet:p-0 "> */}
        <div className="flex flex-col w-full mobile:w-full px-10 tablet:px-0 mx-auto h-full">
          {/* Host Header */}
          <HostNav searchBar={true} setSearch={setSearch} />

          {/* Property Add Alerts  */}
          {successAddProperty && (
            <Alert severity="success">{messageAddProperty}</Alert>
          )}

          {/* Property Update Alerts  */}
          {successUpdate && <Alert severity="success">{messsageupdate}</Alert>}

          {/* Property Delete Alerts */}
          {successDelete && <Alert severity="warning">{messageDelete}</Alert>}

          {
            // if
            isOnBoarded === false ? (
              <div>
                {/* <button onClick={handleClick} className='bg-primary p-2 rounded text-white'>Get OnBoard</button> */}
                {errorOnBorad && <Alert severity="error">{errorOnBorad}</Alert>}
                <div className="flex items-center justify-center">
                  <div className="flex flex-col justify-center items-center max-w-[300px]">
                    <div>
                      <img src={onboard} />
                    </div>
                    <p>
                      Cannot Add Properties until you have set up your Stripe
                      Account
                    </p>
                    <button
                      onClick={handleClick}
                      className="bg-primary p-2 rounded text-white w-full my-5"
                    >
                      {onBoardLoading ? (
                        <CircularProgress
                          color="inherit"
                          className="mr-2"
                          size={"20px"}
                        />
                      ) : (
                        "Link Stripe Account"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                id="host_screen_scroll"
                className="m-auto bg-transparent pb-[30px] my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 overflow-scroll laptop:p-2 flex-grow"
              >
                {/* {message && <h4>No properties to Show</h4> } */}
                {error ? <Alert severity="error">{error}</Alert> : ""}

                <div className="tablet:w-[95%] mx-auto pt-0">
                  {loadingProperties ? (
                    <div className="flex flex-col justify-center gap-2 items-center w-full h-20 rounded-xl bg-lightgrey my-3"></div>
                  ) : propertyList.length === 0 ? (
                    <div className="flex w-full min-h-[400px] flex-grow justify-center items-center">
                      <h1 className="text-base3 font-bold">
                        {search?.length > 0
                          ? "No Match Found"
                          : "You have No Properties Listed"}
                      </h1>
                    </div>
                  ) : (
                    propertyList.map((property) => (
                      <Property
                        key={property._id}
                        property={property}
                        data={data}
                      />
                    ))
                  )}
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>
  );
};

export default HostScreen;
