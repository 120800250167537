import React from "react";
import { NavLink } from "react-router-dom";
import { setBlurOff, setNavOff } from "../../store/slices/hostSlices";
import { useDispatch, useSelector } from "react-redux";
import { getFlags } from "../../store/thunks/hostActions";

const SideNavLink = ({ icon, label, link, active }) => {

    const {hostInfo} = useSelector((state) => {
        return state.host;
    });

    const dispatch = useDispatch();

    return (
        <>
            <NavLink
                to={`/${link}`}
                onClick={(e) => {
                    dispatch(setNavOff())
                    dispatch(setBlurOff())
                    dispatch(getFlags(hostInfo._id));
                }}
                style={({ isActive }) => ({
                    background: isActive ? '#F5F5F5' : 'inherit',
                })}
                className={`${active} link flex justify-start items-center gap-3 w-[90%] rounded-md cursor-pointer p-2 text-base2`}
            >
                <img
                    src={icon}
                    alt={`${label} icon`}
                    className="bg-transparent pointer-events-none"
                />
                <h3 className=" pointer-events-none">{label}</h3>
            </NavLink>
        </>
    );
};

export default SideNavLink;
