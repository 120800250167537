import React from "react";
import ReactModal from "react-modal";
import automationSuccessfullVector from "../icons/automationSuccessfullVector.svg";

const AutomationSuccessfull = ({ showModal, handleCloseModal }) => {
  return (
      <ReactModal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        className="Modal automationModal tablet:w-[70%] w-[50%] max-w-[700px] tablet:h-fit h-fit z-30"
        ariaHideApp={false}
        appElement={document.getElementById("root")}
        overlayClassName="Overlay"
      >
        <div className="flex flex-col p-5 font-segoe">
          <img
            src={automationSuccessfullVector}
            alt="Automation Successful"
            className="m-auto mobile:h-[180px] h-[300px]"
          />
          <h1 className="text-base2 m-aut font-bold text-center">
            Congratulations, Your Automated Messages Are Ready!
          </h1>
          <p className="text-base1 m-auto text-center text-light mt-5 hidden laptop:block">
            Your automated messaging system is now up and running, ready to
            streamline your communication efforts. With this setup, you can send
            personalized and timely messages effortlessly, saving you time and
            ensuring your messages are consistently delivered. Say goodbye to
            the hassle of setting up tedious automated messages. Get started
            today and experience the convenience of automated messaging with
            Bellhopt.
          </p>
        </div>
      </ReactModal>
  );
};

export default AutomationSuccessfull;
