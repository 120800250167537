import React from "react";
import HostNav from "../components/hostComponents/HostNav";
import gmailVector from "../icons/gmailVector.svg";
import questionVector from "../icons/questionVector.svg";
import { Alert, CircularProgress } from "@mui/material";
import { useState } from "react";
import ToggleButton from "../components/utilityComponents/ToggleButton";
import request from "../utils/request";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import CharacterCounter from "../components/hostComponents/CharacterCounter";
import EmailPreview from "../components/hostComponents/EmailPreview";
import AutomationSuccessfull from "../components/AutomationSuccessfull";
import AutomationFailed from "../components/AutomationFailed";
import { useDispatch, useSelector } from "react-redux";
import { getHostProfile } from "../store/thunks/hostActions";

const MessageCentreScreen = () => {
  let newWindow = null;
  const hid = JSON.parse(localStorage.getItem("hostInfo"))._id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Info } = useSelector((state) => state.hostDetails);
  const [queryParam] = useSearchParams();
  const [loading, setLoading] = useState(false);
  // error
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [response, setResponse] = useState();

  const [body1, setBody1] = useState(true);
  const [body2, setBody2] = useState(false);
  const [body3, setBody3] = useState(false);

  const [message, setMessage] = useState("");
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [popupWindow, setPopupWindow] = useState(null);
  const [grantCode, setGrantCode] = useState();

  // useSelector -----------------------
  const { automationAllowed } = useSelector((state) => state.hostFlags);

  // ------------------------ HANDLERS ---------------------------------

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    setShowFailureModal(false);
  };

  const updateStatus = async () => {
    try {
      console.log(Info._id);
      const { data } = await request.patch(`/hosts/automation/${Info._id}`, {
        automationActive: Info?.automationActive ? false : true,
      });
      if (data.success) {
        setSuccess(data.msg);
        dispatch(getHostProfile(Info._id));
      }
    } catch (error) {
      console.log(error);
      setError(error.response.data.error);
    }
  };

  const getMessageBodies = async () => {
    try {
      const { data } = await request.get(
        `/hosts/automation/messagebody/${hid}`
      );
      if (data.success) {
        setResponse(data.messageBody);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveMessageHandler = async () => {
    if (!message) {
      setError("Message Body cannot be empty");
      return;
    }
    try {
      console.log(body1, body2, body3);
      const { data } = await request.patch(
        `/hosts/automation/messagebody/${Info._id}`,
        {
          ...(body1 === true ? { body1: message } : undefined),
          ...(body2 === true ? { body2: message } : undefined),
          ...(body3 === true ? { body3: message } : undefined),
        }
      );

      if (data.success) {
        setSuccess(data.msg);
      }
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  const handleIntegrateEmailBtn = async () => {
    setLoading(true);
    const { data } = await request.get("/hosts/oauth");
    // Calculating the center position for the popup window
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const popupWidth = 500;
    const popupHeight = 500;
    const left = (screenWidth - popupWidth) / 2;
    const top = (screenHeight - popupHeight) / 2;

    newWindow = window.open(
      data.url,
      "_blank",
      `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
    );
    setLoading(false);

    // Handling popup blockers
    if (newWindow) {
      setPopupWindow(true);

      const checkRedirectUrl = setInterval(() => {
        try {
          if (
            newWindow.location.href.includes(
              !process.env.NODE_ENV || process.env.NODE_ENV === "development"?
              "http://localhost:3000/messageCentre?code":
              "https://host.bellhopt.com/messageCentre?code"
            )
          ) {
            // console.log(newWindow.location.href);
            const currentUrl = newWindow.location.href;
            const url = new URL(currentUrl);
            const codeParam = url.searchParams.get("code");
            // console.log(codeParam);
            setGrantCode(codeParam);

            newWindow.close();
            setPopupWindow(false);
            clearInterval(checkRedirectUrl);
          }
        } catch (error) {
          setError(error.response.data.error);
          console.error(error);
        }
      }, 1000);
    } else {
      setError("Popup window was blocked by the browser.");
      throw new Error("Popup window was blocked by the browser.");
    }
  };

  //   save grant code function


  //  -------------- useEffect ---------------
  useEffect(() => {
    const checkPopupClosed = setInterval(() => {
      const saveGrantCode = async (grantCode) => {
        try {
          const { data } = await request.post(
            `/hosts/oauth/callback?code=${grantCode}`
          );
          if (data.success) {
            dispatch(getHostProfile(Info._id));
            setShowSuccessModal(true);
          } else {
            setShowFailureModal(true);
          }
        } catch (error) {
          // window.close();
          setShowFailureModal(true);
          console.log(error?.response?.data?.error);
        }
        setGrantCode(null);
      };
      console.log(grantCode);
      console.log("hi2");
      clearInterval(checkPopupClosed);
      if (grantCode) {
        saveGrantCode(grantCode);
      }
    }, 1000);
  }, [popupWindow, grantCode, Info?._id, dispatch]);

  useEffect(() => {
    if (success || error) {
      setTimeout(() => {
        success && setSuccess("");
        error && setError("");
      }, 2000);
    }
    if (success) {
      getMessageBodies();
    }
  }, [success, error]);

  useEffect(() => {
    if (response) {
      setMessage(
        body1 ? response.body1 : body2 ? response.body2 : response.body3
      );
    }
    if (!response) {
      getMessageBodies();
    }
  }, [response]);

  // useEffect(() => {
  //   if (!automationAllowed) {
  //     navigate("/host");
  //   }
  // }, []);
  return (
    <div className="flex w-full h-screen overflow-auto">
      <AutomationSuccessfull
        showModal={showSuccessModal}
        handleCloseModal={handleCloseModal}
      />
      <AutomationFailed
        showModal={showFailureModal}
        handleCloseModal={handleCloseModal}
      />

      {success && (
        <Alert
          severity="success"
          sx={{
            position: "fixed",
            width: "90%",
            margin: "auto",
            top: 10,
            left: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          {success}
        </Alert>
      )}
      {error && (
        <Alert
          severity="warning"
          sx={{
            position: "fixed",
            width: "90%",
            margin: "auto",
            top: 10,
            left: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          {error}
        </Alert>
      )}
      <div className="flex flex-col w-full mobile:w-full px-10 tablet:px-0 mx-auto">
        <HostNav dashboard={true} hideproperty={true} />

        <div className="no-scrollbar w-full overflow-auto m-auto shadow-4xl bg-white my-7 items-start p-5 rounded-lg tablet:w-[90%]">
          {/* Email Automation box */}

          <div className="flex flex-col messageCenter:flex-row h-auto messageCenter:h-full w-full justify-around gap-[50px] overflow-y-scroll no-scrollbar">
            <div className="w-full">
              <div className="w-full border border-lightborder rounded-xl p-3 flex flex-col gap-5 ">
                {/* Box Heading */}
                <label className="text-2xl font-bold">
                  Automated Message Integration
                </label>

                {/* Integrate Email Btn Functionality */}
                <div className="flex gap-2 items-center">
                  <label className="text text-light font-medium">
                    Integrate Email
                  </label>
                  <button className="">
                    <img src={questionVector} alt="question-vector" />
                  </button>
                  <button
                    className={`pl-2 ${
                      Info?.automation && "cursor-not-allowed"
                    }`}
                    onClick={() => handleIntegrateEmailBtn()}
                    disabled={Info?.automation}
                  >
                    <img src={gmailVector} alt="gmail-vector" />
                  </button>
                  {Info?.automation && (
                    <label className="bg-[#00B227] text-white font-normal py-[1px] px-3 ml-2 text-center rounded-2xl text-sm">
                      Active
                    </label>
                  )}
                  {loading && <CircularProgress size={20} />}
                </div>

                {/* Automated Messages Toggle Functionality */}
                <div className="flex gap-3 items-center">
                  <label className="text text-light font-medium">
                    Automated Messages
                  </label>
                  <button className="">
                    <img src={questionVector} alt="question-vector" />
                  </button>

                  {/* ToggleButton */}
                  <ToggleButton
                    isChecked={Info?.automationActive}
                    updateStatus={updateStatus}
                  />
                </div>
              </div>

              {/* MessageBody */}
              <div className="w-full flex flex-col gap-5 justify-between ">
                <h1 className="text-[24px] font-bold mt-5">Message Body</h1>
                <CharacterCounter message={message} setMessage={setMessage} />
              </div>
              <div className="w-full text-right mt-2">
                <button
                  onClick={saveMessageHandler}
                  className="bg-white hover:bg-black hover:text-white border-2 rounded-lg px-5 py-1"
                >
                  {body1
                    ? "Save Message 1"
                    : body2
                    ? "Save Message 2"
                    : "Save Message 3"}
                </button>
              </div>
            </div>

            <div className="no-scrollbar h-[600px] mx-auto messageCenter:h-full overflow-y-scroll border border-lightborder rounded-xl max-w-[536px] min-w-[536px]">
              <h1 className="text-center font-bold text-[30px] py-1">
                Email Preview
              </h1>

              {/* check Boxes */}
              <div className="flex flex-row w-full justify-around items-center mb-2 py-1 bg-white sticky top-[-1px] z-20">
                <div className="flex items-center space-x-2">
                  <input
                    type="radio"
                    id="body1"
                    name="body"
                    checked={body1}
                    onChange={() => {
                      setBody1(true);
                      setBody2(false);
                      setBody3(false);
                      setMessage(response.body1 ? response.body1 : "");
                    }}
                    className="form-radio h-5 w-5 text-primary cursor-pointer"
                  />
                  <label htmlFor="body1">Body 1</label>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    type="radio"
                    id="body2"
                    name="body"
                    checked={body2}
                    onChange={() => {
                      setBody1(false);
                      setBody2(true);
                      setBody3(false);
                      setMessage(response.body2 ? response.body2 : "");
                    }}
                    className="form-radio h-5 w-5 text-primary cursor-pointer"
                  />
                  <label htmlFor="body1">Body 2</label>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    type="radio"
                    id="body3"
                    name="body"
                    checked={body3}
                    onChange={() => {
                      setBody1(false);
                      setBody2(false);
                      setBody3(true);
                      setMessage(response.body3 ? response.body3 : "");
                    }}
                    className="form-radio h-5 w-5 text-primary cursor-pointer"
                  />
                  <label htmlFor="body1">Body 3</label>
                </div>
              </div>
              <EmailPreview message={message} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageCentreScreen;
