import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import profileIcon from "../../icons/profileIcon.png";
import {
  setBlurOn,
  setBlurOff,
  setNavOn,
  setNavOff,
} from "../../store/slices/hostSlices";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/hostSlices";
import { useNavigate } from "react-router-dom";
import {
  getHostEarnings,
  getHostProfile,
  getHostProfileImage,
  updateHostProfile,
} from "../../store/thunks/hostActions";
import hamburger from "../../icons/hamburger.png";
import { Alert, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import request from "../../utils/request";
import CsvExport from "./CsvExport";

function HostNav({ searchBar, setSearch, hide, edit }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useStates
  const [error, setError] = useState();
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [frontURL, setFrontURL] = useState();
  const [frontURLPreview, setFrontUrlPreview] = useState();
  const fileInputFront = useRef(null);

  // For Login Check
  const { hostInfo } = useSelector((state) => state.host);

  // For Host Details
  const { Info, loading: loadingDetails, loadingImage } = useSelector(
    (state) => state.hostDetails
  );
  const { loading, earnings } = useSelector((state) => state.hostEarnings);
  // Property Details UseSelector Hook
  const { property } = useSelector((state) => state.propertyDetails);

  // summing up the Earnings
  let total = 0;
  for (const earning in earnings) {
    const sum = earnings[earning].earnings.reduce(
      (acc, amount) => acc + amount,
      0
    );
    total += sum;
  }

  let firstName, lastName, isManager, isOnBoarded;
  if (Info) {
    firstName = Info.firstName;
    lastName = Info.lastName;
    isManager = Info.isManager;
    isOnBoarded = Info.isOnBoarded;
  }

  const { hostBlur } = useSelector((state) => state);
  const onClickHam = () => {
    dispatch(setNavOn());
    dispatch(setBlurOn());
  };

  // handlers
  // ImageUpload Handlers ---------------------------------------
  const handleOnDragOver = (event) => {
    event.preventDefault();
  };
  const handleFile = async (e) => {
    setUploading(true);
    const file = e.target.files[0];
    if(!file) return;
    setFrontURL(file);

    const formData = new FormData();
    formData.append("profilePicture", file);
    try {
      const { data } = await request.patch(`/hosts/${Info?._id}`, formData, {
        header: {
          "Content-type": "multipart/form-data",
        },
      });

      setSuccess(true);
      setUploading(false);
      await dispatch(getHostProfile(hostInfo._id));
      dispatch(getHostProfileImage());
    } catch (error) {
      setUploading(false);
      setSuccess(false);
      console.log(error.response.data.error);
      setError(error.response.data.error);
    }
  };
  const handleOnDrop = (event) => {
    //prevent the browser from opening the image
    event.preventDefault();
    event.stopPropagation();

    handleFile(event);
  };

  useEffect(() => {
    // if (!hostInfo) {
    //     dispatch(logout());
    //     navigate('/login');
    // }
    // else {
    if (!Info) {
      dispatch(getHostProfile(hostInfo._id));
      dispatch(getHostEarnings(hostInfo._id));
      console.log("heya");
    }
    // }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      error && setError(null);
      success && setSuccess(null);
    }, 2000);
  }, [error, success]);

  return (
    <>
      {hostBlur && (
        <div
          onClick={() => {
            dispatch(setBlurOff());
            dispatch(setNavOff());
          }}
          className={`absolute opacity-40 top-0 left-0 h-screen w-full bg-black z-10 min-h-[650px]`}
        ></div>
      )}

      <div
        className={`tablet:flex-col flex-row justify-between w-full laptop:min-h-[120px] tablet:gap-y-[10px] tablet:p-5 flex items-center gap-x-20 tablet:shadow-4xl laptop:border-b-[1px] border-lightborder mobile:items-start`}
      >
        {/* mui alert for error */}
        {error && (
          <Alert
            severity="error"
            sx={{
              position: "fixed",
              width: "90%",
              margin: "auto",
              top: 20,
              left: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            {error}
          </Alert>
        )}
        {success && (
          <Alert
            severity="success"
            sx={{
              position: "fixed",
              width: "90%",
              margin: "auto",
              top: 20,
              left: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            Profile Picture Updated
          </Alert>
        )}
        <div className="flex mobile:flex-row-reverse gap-5 items-center justify-between tablet:w-full">
          <div className="flex mobile:flex-row-reverse gap-5 items-center content-center min-w-[230px]">
            <div>
              <div
                className={`drop_zone ${!edit && " pointer-events-none"}`}
                onDragOver={handleOnDragOver}
                onDrop={handleOnDrop}
                onClick={() => fileInputFront.current.click()}
              >
                <button className="">
                  {uploading || loadingImage ? (
                    <CircularProgress size={50} />
                  ) : frontURLPreview ? (
                    <img
                      src={frontURLPreview}
                      alt="host-image"
                      className="min-w-[70px] w-[70px] h-[70px] rounded-full p-2 cursor-pointer"
                    />
                  ) : (
                    <>
                      <img
                        src={Info?.profilePicture}
                        alt="image-placeholder"
                        className="min-w-[70px] w-[70px] h-[70px] rounded-full cursor-pointer"
                      />
                    </>
                  )}
                </button>
                <input
                  id="frontInput"
                  className="bg-black"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  ref={fileInputFront}
                  hidden
                  onChange={(e) => handleFile(e)}
                />
              </div>
            </div>
            <div>
              <h5 className="font-segoe">
                {firstName} {lastName}
              </h5>
              {isManager && (
                <p className="text-base1 font-segoeBold">Property Manager </p>
              )}
            </div>
          </div>

          {/* Hamburger For only Mobile and Tablet screen */}
          <button
            id="hamburger"
            onClick={onClickHam}
            className=" text-black font-bold py-2 px- rounded-lg w-[50px] h-[50px] laptop:hidden"
          >
            <img
              src={hamburger}
              alt=""
            />
          </button>
        </div>

        {/* Search Box and the button */}
        <div className="flex gap-3 items-center tablet:w-full tablet:justify-end">
          {searchBar && (
            <input
              type="text"
              placeholder={`Search property...`}
              className="m-auto tablet:w-full w-[300px] bg-lightgrey rounded-lg py-3 px-5"
              onChange={(e) => setSearch(e.target.value)}
            />
          )}

          {
            // A rounded button with black border to show host earnings
            <div className="py-3 px-6 border rounded-[100px] w-fit tablet:hidden">
              <p>{`$${total?.toFixed(2)}`}</p>
            </div>
          }

          <NavLink
            to="/addProperty"
            className={`${
              !isOnBoarded && "pointer-events-none"
            } flex justify-center items-center bg-black text-white font-bold py-3 px-4 rounded-[100px] min-w-[140px] tablet:hidden ${
              hide === true && "hidden"
            }`}
          >
            Add Property
          </NavLink>
          <div className="tablet:hidden">
            <CsvExport />
          </div>
        </div>
      </div>
    </>
  );
}

export default HostNav;
