import React from "react";
import { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
  PieChart,
  Pie,
  Sector,
  Cell,
  Label,
} from "recharts";

// import Icon from '../../icons/admin/Icon.png'
// import Icon2 from '../../icons/admin/ic_order-red.png'


const data = [
  { day: "10", earnings: 100 },
  { day: "11", earnings: 150 },
  { day: "12", earnings: 200 },
  { day: "13", earnings: 10 },
  { day: "13", earnings: 20 },
  { day: "13", earnings: 130 },
  { day: "13", earnings: 300 },
  { day: "13", earnings: 500 },
  { day: "13", earnings: 110 },
  { day: "13", earnings: 700 },
  { day: "13", earnings: 230 },
  { day: "13", earnings: 180 },
  { day: "13", earnings: 180 },
  { day: "13", earnings: 180 },

  // and so on for the rest of the days in the month
];

const data1 = [
  { day: 10, customers: 5 },
  { day: 11, customers: 3 },
  { day: 12, customers: 7 },
  { day: 13, customers: 2 },
  { day: 14, customers: 9 },
  { day: 15, customers: 6 },
  { day: 16, customers: 4 },
  { day: 17, customers: 7 },
  { day: 18, customers: 2 },
  { day: 19, customers: 9 },
  { day: 20, customers: 6 },
  { day: 21, customers: 4 },
  { day: 22, customers: 6 },
  { day: 23, customers: 4 },
  { day: 24, customers: 7 },
];

const data2 = [
  { name: "Completed", orders: 1275 },
  { name: "Active", orders:895 },
  { name: "Canceled", orders: 500 },
];

const COLORS = ["#EF4949", "#2BC155", "#009DDD"];

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="tooltip">
        <p className="label">{`${label} : $${payload[0].value}`}</p>
        <div className="vertical-line bg-red"></div>
      </div>
    );
  }

  return null;
};

function AdminComponent() {
  const [activePoint, setActivePoint] = useState(null);

  return (
    <>
      <div className="flex items-center justify-between p-3 laptop:justify-around shadow-4xl bg-white my-3 rounded-xl w-full py-5 tablet:p-5 mobile:mx-0 gap-x-1">
        <div className="w-[30%] border-r-[2px]">
          <div className="flex gap-5 items-center content-center">
            <div className="min-w-[70px] w-[70px] h-[70px] rounded-full bg-[#F5F5F5]">
            {/* <img src={Icon} alt="My Icon" className="p-5"/> */}
            </div>
            <div>
              <h5 className="font-segoe">Total Revenue</h5>
              <p className="text-base1 font-segoeBold">$3,76350 </p>
            </div>
          </div>
        </div>

        <div className="w-[30%] border-r-[2px]">
          <div className="flex gap-5 items-center content-center">
            <div className="min-w-[70px] w-[70px] h-[70px] rounded-full bg-[#F5F5F5]">
            {/* <img src={Icon2} alt="My Icon" className="p-5"/> */}
            </div>
            <div>
              <h5 className="font-segoe">Total Revenue</h5>
              <p className="text-base1 font-segoeBold">$3,76350 </p>
            </div>
          </div>
        </div>

        <div className="w-[30%]">
          <div className="flex gap-5 items-center content-center">
            <div className="min-w-[70px] w-[70px] h-[70px] rounded-full bg-[#F5F5F5]">
            {/* <img src={Icon} alt="My Icon" className="p-5"/> */}
            </div>
            <div>
              <h5 className="font-segoe">Total Revenue</h5>
              <p className="text-base1 font-segoeBold">$3,76350 </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="w-[70%] h-[334px] p-3 shadow-4xl bg-white my-3 rounded-xl py-5 tablet:p-5 mobile:mx-0 gap-x-1">
          <div className="w-[95%] m-auto">
            <div className="flex justify-between mb-5">
              <h1 className="font-segoe font-bold text-[24px]">
                Revenue Funnel
              </h1>
              <button>This month</button>
            </div>

            <ResponsiveContainer width="100%" height={210}>
              <LineChart data={data} onMouseLeave={() => setActivePoint(null)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Line
                  type="monotone"
                  dataKey="earnings"
                  stroke="red"
                  // strokeWidth={2}
                  // dot={{ strokeWidth: 2, r: 5 }}
                  onMouseOver={(data, index) => setActivePoint(index)}
                  opacity={
                    activePoint !== null
                      ? (index) => (index === activePoint ? 1 : 0.3)
                      : 1
                  }
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="w-[28%] h-[334px] p-3 shadow-4xl bg-white my-3 rounded-xl py-5 tablet:p-5 mobile:mx-0 gap-x-1">
          <div className="w-[95%] m-auto">
            <div className="flex justify-between mb-5">
              <h1 className="font-segoe font-bold text-[20px]">Orders</h1>
              <button>This month</button>
            </div>

              <div className="flex items-center">
                <PieChart width={150} height={140}>
                  <Pie
                    data={data2}
                    cx={50}
                    cy={60}
                    innerRadius={25}
                    outerRadius={50}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="orders"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>


                <div>
                <p>Total Earning</p>
                <h1 className="font-segoe text-[40px] font-bold">2,270</h1>
                </div>

              </div>

              <div>
                <p>Completed Orders</p>
                <p>Active Orders</p>
                <p>Cancelled</p>
              </div>
          
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="w-[70%] h-[334px] p-3 shadow-4xl bg-white my-3 rounded-xl py-5 tablet:p-5 mobile:mx-0 gap-x-1">
          <div className="w-[95%] m-auto">
            <div className="flex justify-between mb-5">
              <h1 className="font-segoe font-bold text-[24px]">
                Guests/Customers Map
              </h1>
              <button>This month</button>
            </div>

            <BarChart width={800} height={210} data={data1}>
              <XAxis dataKey="day" stroke="#8884d8" />
              <YAxis />
              <Tooltip wrapperStyle={{ width: 100, backgroundColor: "#ccc" }} />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Bar dataKey="customers" fill="red" barSize={7} />
            </BarChart>
          </div>
        </div>

        <div className="w-[28%] h-[334px] p-3 shadow-4xl bg-white my-3 rounded-xl py-5 tablet:p-5 mobile:mx-0 gap-x-1">
          <div className="flex justify-between">
            <h1 className="font-segoe font-bold text-[20px]">Recent orders</h1>
            <button>view all</button>
          </div>

          <div className="flex flex-col justify-between">
            <div className="flex justify-between items-center mt-5">
              <div>
                <div className="flex gap-5 items-center content-center">
                  <div className="min-w-[70px] w-[70px] h-[70px] rounded-full bg-[#F5F5F5]"></div>
                  <div>
                    <h5 className="font-segoe">Total Revenue</h5>
                    <p className="text-base1 font-segoeBold">$3,76350 </p>
                  </div>
                </div>
              </div>

              <div>$123</div>
            </div>

            <div className="flex justify-between items-center mt-5">
              <div>
                <div className="flex gap-5 items-center content-center">
                  <div className="min-w-[70px] w-[70px] h-[70px] rounded-full bg-[#F5F5F5]"></div>
                  <div>
                    <h5 className="font-segoe">Total Revenue</h5>
                    <p className="text-base1 font-segoeBold">$3,76350 </p>
                  </div>
                </div>
              </div>

              <div>$123</div>
            </div>

            <div className="flex justify-between items-center mt-5">
              <div>
                <div className="flex gap-5 items-center content-center">
                  <div className="min-w-[70px] w-[70px] h-[70px] rounded-full bg-[#F5F5F5]"></div>
                  <div>
                    <h5 className="font-segoe">Total Revenue</h5>
                    <p className="text-base1 font-segoeBold">$3,76350 </p>
                  </div>
                </div>
              </div>

              <div>$123</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminComponent;