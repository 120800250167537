import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import TextBox from '../components/TextBox'
import request from '../utils/request';
import { Alert, CircularProgress } from '@mui/material';
import { useFormik } from "formik";
import { forgetSchema } from "../schemas";
import { Link } from 'react-router-dom';
import emailSent from '../icons/emailSent.svg'

const ForgetPasswordScreen = () => {

    // const [credentials, setCredentials] = useState({ email: "" });
    const [success, setSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const [initialValues, setInitialValues] = useState({
        email: "",
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik(
        {
            initialValues: initialValues,
            validationSchema: forgetSchema,
        }
    )

    const submitHandler = async (e) => {
        e.preventDefault();
        if (Object.keys(errors).length == 0) {
            setSuccessMsg('')
            setError('')
            setSuccess(false);

            setLoading(true);
            try {
                // console.log(credentials.email);
                const { data } = await request.post(
                    "/hosts/forgotpassword",
                    values,
                    { withCredentials: true }
                );
                console.log(data);
                setLoading(false);
                setSuccess(true);
                setSuccessMsg(data.message);
            } catch (err) {
                console.log(err.response.data);
                setLoading(false);
                setError(err.response.data.error);
            }
        }
    }

    useEffect(() => {

        setTimeout(() => {

            error && setError(null)
            // success && setSuccess(false)
            successMsg && setSuccessMsg(null)
        }, 3000);

    }, [error, successMsg]);

    return (
        <>

            <div className='w-full h-screen'>

                <Header />
                {error && (
                    <Alert
                        severity="error"
                        sx={{
                            position: "fixed",
                            width: "90%",
                            margin: "auto",
                            top: 20,
                            left: 0,
                            right: 0,
                            zIndex: 10,
                        }}
                    >
                        <strong>{error}</strong>
                    </Alert>
                )}
                {successMsg && (
                    <Alert
                        severity="success"
                        sx={{
                            position: "fixed",
                            width: "90%",
                            margin: "auto",
                            top: 20,
                            left: 0,
                            right: 0,
                            zIndex: 10,
                        }}
                    >
                        <strong>{successMsg}</strong>
                    </Alert>
                )}
                <div className="h-[500px] tablet:h-[400px] bg-white my-7 overflow-auto flex flex-col justify-around items-center">
                    {
                        success ? (
                            <>
                            {/* Success Component */}
                            <div>
                                <img src={emailSent} alt="Email Sent" className="w-[200px] mobile:w-[150px] h-[200px] mobile:h-[150px] m-auto" />
                                <div className="top mt-7 text-center">
                                    <h2 className="font-bold text-base3 mobile:text-base2">Check Your Inbox</h2>                        
                                </div>
                                <div className='top text-center'>
                                    <p class="text-[#919191] text-base1 mobile:text-sm w-[500px] mobile:w-[300px]">
                                    We’ve sent you an email with instructions on how to reset your password to <b className='text-black'>{values.email}</b>
                                    </p>
                                </div>
                                <div className='top mt-5 text-center'>
                                    <p className="text-base1 text-light">
                                        Didn't get the email?
                                    </p>
                                    <button className="bg-primary w-full h-[50px] rounded-[40px] mt-1" onClick={() => setSuccess(false)}>
                                        <p className="font-bold text-white text-base2">Send Again</p>
                                    </button>
                                </div>
                            </div>  
                            </>
                        ) : (
                    <>
                   
                    <div className="top mt-7 text-center">
                        <h2 className="font-bold text-lg mobile:text-base3">Forgot Password?</h2>                        
                    </div>
                    <div className='top mt-1 text-center'>
                        <p class="text-[#919191] text-base2 mobile:text-sm w-[500px] mobile:w-[300px]">
                            Don't worry, it happens to the best of us.
                            <br />
                            Just follow these simple steps to reset your password securely and regain access to your account.
                        </p>
                    </div>
                    

                    <form className="mb-5 w-[450px] mobile:w-[300px]">
                        <TextBox
                            name={"email"}
                            type={"email"}
                            credentials={values}
                            value={values.email}
                            onChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors}
                            placeholder={"Email Address"}
                        // setValue={setCredentials}
                        />


                        <div className="w-full text-center">
                            <button
                                onClick={submitHandler}
                                className="bg-primary w-full h-[50px] rounded-[40px] mt-10"
                            >
                                <p className="font-bold text-white text-base2">
                                    {loading ? <CircularProgress color="inherit" className="mr-2" size={'20px'} /> : "Send Instructions"}</p>
                                {/* <p className="font-bold text-white text-base2">Change Password</p> */}
                            </button>
                        </div>
                        <p className="text-base1 text-light my-7">
                            <Link to={"/login"}> &#60; Go back and try one more time</Link>                                        
                        </p>
                    </form>
                    </>)}
                </div>
            </div>
        </>
    )
}

export default ForgetPasswordScreen