import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import successIcon from '../icons/success.svg'

const EmailVerificationSuccess = () => {

    return (
        <>

            <div className="w-[90%] m-auto shadow-4xl h-[500px] tablet:h-[400px] bg-white my-7 overflow-auto flex flex-col justify-center items-center p-5">
                <h1 className='text-lg font-bold text-center'>Email Verification Successful</h1>
                <img src={successIcon} className='w-30 mt-10' alt="success icon" />

                <p className="text-base2 text-light ">
                    Go Back to Login {" "}
                    <label className=" text-primary">
                        <Link to={"/login"}>Login</Link>
                    </label>
                </p>

            </div>
        </>
    )
}

export default EmailVerificationSuccess