import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import SideNav from "./components/hostComponents/SideNav";
import {
  LoginScreen,
  SignupScreen,
  HomeScreen,
  HostScreen,
  AddPropertyScreen,
  ProfileSettingScreen,
  PrivacyPolicyScreen,
  PropertyDetailsScreen,
  PleaseVerifyEmailScreen,
  VerificationScreen,
  ForgetPasswordScreen,
  ChangePasswordScreen,
  Admin,
  DriverStripeRedirectScreen
} from "./screens";
import HostDashboard from "./screens/HostDashboard";
import SupportScreen from "./screens/SupportScreen";
import DriverEmailVerificationScreen from "./screens/DriverEmailVerificationScreen";
import OrdersScreen from "./screens/OrdersScreen";
import PageNotFoundScreen from "./screens/PageNotFoundScreen";
import PrivateRoutes from "./components/utilityComponents/PrivateRoutes";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import PasswordResetSuccessful from "./screens/PasswordResetSuccessful";
import ChangeEmailScreen from "./screens/ChangeEmailScreen";
import ChangeEmailVerificationScreen from "./screens/ChangeEmailVerificationScreen";
import MessageCentreScreen from "./screens/MessageCentreScreen";
import Email from "./screens/Email";

const App = () => {
  const { loading, hostInfo, error } = useSelector((state) => {
    return state.host;
  });

  

  return (
    <>
      {/* We have to use !hostInfo condition as per logic */}
      <div className="flex w-full min-h-screen overflow-aut">
        {hostInfo && <SideNav />}
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/host" element={<HostScreen />} />
            <Route path="addProperty" element={<AddPropertyScreen />} />
            <Route path="/profileSetting/:hid" element={<ProfileSettingScreen />} />
            <Route path="/host/propertyDetails/:pid" element={<PropertyDetailsScreen />} />
            <Route path="/hostdashboard" element={<HostDashboard />} />
            <Route path="/orderList" element={<OrdersScreen />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/profileSetting/:hid/reset-password" element={<ResetPasswordScreen />} />
            <Route path="/profileSetting/:hid/change-email" element={<ChangeEmailScreen />} />
            <Route path="/messageCentre" element={<MessageCentreScreen />} />
            <Route path="/email" element={<Email />} />

          </Route>
          <Route path='/' element={<LoginScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/signup" element={<SignupScreen />} />
          <Route path="/please-verify" element={<PleaseVerifyEmailScreen />} />
          <Route path="/verify-email/:token" element={<VerificationScreen />} />
          <Route path="/login/forgetPassword" element={<ForgetPasswordScreen />} />
          <Route path="/resetPassword/:token" element={<ChangePasswordScreen host={true} />} />
          <Route path="/password-reset/successful" element={<PasswordResetSuccessful />} />
          <Route path="/support" element={<SupportScreen />} />
          <Route path='/update-email-confirmation/:token' element={<ChangeEmailVerificationScreen />} />

          {/* DRIVER ROUTES */}
          <Route path="/driver/verify-email/:token" element={<DriverEmailVerificationScreen />} />
          <Route path="/driver/resetPassword/:token" element={<ChangePasswordScreen driver={true} />} />
          <Route path="/driver/stripe" element={<DriverStripeRedirectScreen />} />

          {/* Not Found ROute */}
          <Route path="*" element={<PageNotFoundScreen />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
