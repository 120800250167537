import React from 'react'
import successIcon from '../../icons/success.svg'

const DriverEmailVerificationSuccess = () => {
    return (

        <div className="w-[90%] m-auto shadow-4xl h-[500px] tablet:h-[300px] bg-white my-7 overflow-auto flex flex-col justify-around items-center mobile:p-5 borde">
            <h1 className='text-base2 font-bold py-2 px-4'>Email Verification Successful</h1>
            <h2 className='text-base1 text-center'>Your Email has been verified successfully</h2>
            <img src={successIcon} className='w-20' alt="success icon" />
            <h2 className='text-center font-semibold'>Visit the mobile app to Login</h2>
        </div>
    )
}

export default DriverEmailVerificationSuccess