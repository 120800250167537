import React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import DriverEmailVerificationFailed from '../components/driver/DriverEmailVerificationFailed';
import DriverEmailVerificationSuccess from '../components/driver/DriverEmailVerificationSuccess';
import Header from '../components/Header';
import request from '../utils/request';

const DriverEmailVerificationScreen = () => {

    const params = useParams();

    const [success, setSuccess] = useState();
    const [error, setError] = useState(false);

    // email token
    const verifcationToken = params.token;

    const verifyEmail = async (token) => {

        try {

            const { data } = await request.get(`/drivers/verify-email/${token}`)


            if (data.success) {
                setSuccess(true);
            }
        } catch (error) {

            setSuccess(false);
            setError(error.response.data.error)
            console.log(error.message);
        }
    }

    useEffect(() => {
        
        verifyEmail(verifcationToken);
    }, []);

    return (
        <div className='w-full h-screen'>

            <Header />
            {success === true ? <DriverEmailVerificationSuccess /> : success === false ? <DriverEmailVerificationFailed error={error} /> : null}

            {/* <DriverEmailVerificationSuccess /> */}

        </div>
    )
}

export default DriverEmailVerificationScreen