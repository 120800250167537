import React, { useEffect } from "react";
import HostNav from "../components/hostComponents/HostNav";
import DashboardComponent from "../components/hostComponents/DashboardComponent";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../store/slices/hostSlices";
import request from "../utils/request";
import onboard from "../icons/onboard.svg";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { getFlags } from "../store/thunks/hostActions";

function HostDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [onBoardLoading, setOnBoardLoading] = useState(false);

  const { hostInfo } = useSelector((state) => {
    return state.host;
  });
  const isOnBoarded = useSelector((state) => {
    return state.hostDetails.Info?.isOnBoarded;
  });

  const handleClick = async () => {
    setOnBoardLoading(true)
    const { data } = await request.get("/stripe/get-onboarding-link");
    window.location.href = data.url;
    console.log(data);
  };

  useEffect(() => {
    dispatch(getFlags(hostInfo._id));
  }, []);
  return (
    <div className="flex w-full h-screen overflow-auto">
      <div className="flex flex-col w-full mobile:w-full px-10 tablet:px-0 mx-auto">
        <HostNav dashboard={true} hideproperty={true} />
        {
          // if
          isOnBoarded ? (
            <DashboardComponent />
          ) : (
            // else
            <div>
              {/* <button onClick={handleClick} className='bg-primary p-2 rounded text-white'>Get OnBoard</button> */}
              <div className="flex items-center justify-center">
                <div className="flex flex-col justify-center items-center max-w-[300px]">
                  <div>
                    <img src={onboard} />
                  </div>
                  <p>
                  Cannot Add Properties until you have set up your Stripe Account
                  </p>
                  <button
                    onClick={handleClick}
                    className="bg-primary p-2 rounded text-white w-full my-5"
                  >
                    {onBoardLoading ? <CircularProgress
                      color="inherit"
                      className="mr-2"
                      size={"20px"}
                    /> :
                      'Link Stripe Account'
                    }
                  </button>

                  {/* <div className="">
                    <p className="text-[14px]">
                      Don’t have a Stripe account?{" "}
                      <label className=" text-primary">
                        <Link to={"/signup"}>Sign Up</Link>
                      </label>
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default HostDashboard;
