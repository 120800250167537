import request from '../utils/request'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import HostNav from '../components/hostComponents/HostNav'
import failedIcon from '../icons/failed.png'
import successIcon from '../icons/success.svg'


const ChangeEmailVerificationScreen = () => {

    const params = useParams();
    // const navigate = useNavigate()
    const [success, setSuccess] = useState();
    const [error, setError] = useState(false);

    // email token
    const verifcationToken = params.token;

    const verifyEmail = async (token) => {

        try {
            const { data } = await request.get(`/hosts/update-email-confirmation/${token}`)

            if (data.success) {
                setSuccess(true);
            }
        } catch (error) {

            setSuccess(false);
            setError(error.response.data.error)
            console.log(error.response.data.error);
        }
    }

    useEffect(() => {

        verifyEmail(verifcationToken);
    }, [verifcationToken]);


    return (
        <>

            <div className='flex w-full h-screen overflow-auto min-h-[500px]'>
                <div className='flex flex-col w-full mobile:w-full px-10 tablet:px-0 mx-auto h-full'>

                <HostNav />
                {/* {success == true ? <EmailVerificationSuccess /> : success == false ? < EmailVerificationFailed error={error} /> : null} */}
                {
                    success ? (
                        <>

                            <div className=" h-[500px] tablet:h-[400px] bg-white my-7 overflow-auto flex flex-col justify-center items-center p-5">
                                <h1 className='text-lg font-bold text-center text-black'>Email Updated Successfully</h1>
                                <img src={successIcon} className='w-30 mt-10' alt="success icon" />

                            </div>
                        </>
                    )
                    : (
                        <>

                            <div className="w-[90%] m-auto shadow-4xl h-[500px] tablet:h-[400px] bg-white my-7 overflow-auto flex flex-col justify-around items-center p-20 mobile:p-5 ">
                                <h1 className='text-lg mobile:text-base3 text-black p-5 text-center'>{error}</h1>
                                <img src={failedIcon} className='w-10' alt="failed icon" />

                            </div>
                        </>
                    )
                }
                {/* <EmailVerificationSuccess /> */}
                {/* < EmailVerificationFailed error={"Love you"} /> */}

                </div>
            </div>
        </>
    )

}

export default ChangeEmailVerificationScreen