import { configureStore } from "@reduxjs/toolkit";

// Host Slices
import { addHostPropertySlice, hostAccountBalanceSlice, hostChangeEmailSlice, hostEarningsSlice, hostFlagsSlice, hostOrdersSlice, hostPendingEarningsSlice, hostProfileSlice, hostResetPasswordSlice } from "./slices/hostSlices";
import { hostPropertiesSlice } from "./slices/hostSlices";
import { hostSignupSlice } from "./slices/hostSlices";
import { hostBlurSlice } from "./slices/hostSlices";
import { hostSideNavSlice } from "./slices/hostSlices";
import { hostPropertyUpdateSlice } from "./slices/hostSlices";
import { hostPropertyDetailsSlice } from "./slices/hostSlices";
import { hostPropertyDeleteSlice } from "./slices/hostSlices";
import { hostUpdateProfileSlice } from "./slices/hostSlices";
import { hostLoginSlice } from "./slices/hostSlices";
import { driverResetPasswordSlice } from "./slices/driverSlices";
import { hostChangePasswordSlice } from "./slices/hostSlices";

const hostInfoFromLocalStorage = localStorage.getItem("hostInfo")
  ? JSON.parse(localStorage.getItem("hostInfo"))
  : null;

const initialState = {
  host: { hostInfo: hostInfoFromLocalStorage },
};

export const store = configureStore({
  reducer: {
    host: hostLoginSlice.reducer,
    hostFlags: hostFlagsSlice.reducer,
    hostProperties: hostPropertiesSlice.reducer,
    hostSignUp: hostSignupSlice.reducer,
    hostBlur: hostBlurSlice.reducer,
    hostSideNav: hostSideNavSlice.reducer,
    propertyUpdate: hostPropertyUpdateSlice.reducer,
    propertyDetails: hostPropertyDetailsSlice.reducer,
    propertyDelete: hostPropertyDeleteSlice.reducer,
    hostDetails: hostProfileSlice.reducer,
    addProperty: addHostPropertySlice.reducer,
    hostUpdate: hostUpdateProfileSlice.reducer,
    hostResetPassword: hostResetPasswordSlice.reducer,
    hostEarnings: hostEarningsSlice.reducer,
    hostPendingEarnings: hostPendingEarningsSlice.reducer,
    hostAccountBalance: hostAccountBalanceSlice.reducer,
    orders: hostOrdersSlice.reducer,
    hostChangePassword: hostChangePasswordSlice.reducer,
    hostChangeEmail: hostChangeEmailSlice.reducer,

    // Driver States
    driverResetPassword: driverResetPasswordSlice.reducer,
  },
  preloadedState: initialState,
});
