import { createSlice } from "@reduxjs/toolkit";
import {
  hostLogin,
  hostSignup,
  getHostProperties,
  updateHostProperty,
  getPropertyDetails,
  deleteHostProperty,
  getHostProfile,
  updateHostProfile,
  addProperty,
  hostResetPassword,
  getHostEarnings,
  getHostPendingEarnings,
  getHostAccountBalance,
  getAllOrders,
  hostChangePassword,
  hostChangeEmail,
  getHostProfileImage,
  getFlags,
} from "../thunks/hostActions";

const hostLoginSlice = createSlice({
  name: "hostLogin",
  initialState: {
    loading: false,
    hostInfo: null,
    error: "",
    token: "",
  },
  reducers: {
    removeError: (state) => {
      state.error = "";
    },
    logout: (state) => {
      state.hostInfo = null;
      localStorage.removeItem("hostInfo");
      localStorage.clear();
    },
    hostLoginReset: (state) => {
      state.loading = false;
      state.success = false;
      state.message = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(hostLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(hostLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.hostInfo = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(hostLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostSignupSlice = createSlice({
  name: "hostSignup",
  initialState: {
    loading: false,
    success: false,
    message: "",
    error: "",
  },
  reducers: {
    hostSignupReset: (state) => {
      state.loading = false;
      state.success = false;
      state.message = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(hostSignup.pending, (state) => {
        state.loading = true;
      })
      .addCase(hostSignup.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(hostSignup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

const hostResetPasswordSlice = createSlice({
  name: "hostResetPassword",
  initialState: {
    loading: false,
    message: null,
    error: "",
    success: false,
  },
  reducers: {
    changePasswordReset: (state) => {
      state.loading = false;
      state.message = null;
      state.error = "";
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(hostResetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(hostResetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.msg;
        state.success = true;
      })
      .addCase(hostResetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.success = false;
      });
  },
});

const hostProfileSlice = createSlice({
  name: "hostProfile",
  initialState: {
    loading: false,
    Info: null,
    error: "",
    loadingImage: false,
    imageError: "",
  },
  reducers: {
    hostProfileReset: (state) => {
      state.loading = false;
      state.Info = null;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHostProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHostProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.Info = action.payload;
      })
      .addCase(getHostProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      })
      .addCase(getHostProfileImage.pending, (state) => {
        state.loadingImage = true;
      })
      .addCase(getHostProfileImage.fulfilled, (state, action) => {
        state.loadingImage = false;
        state.Info.profilePicture = action.payload;
      })
      .addCase(getHostProfileImage.rejected, (state, action) => {
        state.loadingImage = false;
        state.imageError = action.payload?.error;
      });
  },
});

const hostUpdateProfileSlice = createSlice({
  name: "hostUpdate",
  initialState: {
    loading: false,
    success: false,
  },
  reducers: {
    profileUpdateReset: (state) => {
      state.loading = false;
      state.success = false;
      state.message = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateHostProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateHostProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.message = action.payload.msg;
      })
      .addCase(updateHostProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostChangePasswordSlice = createSlice({
  name: "hostChangePassword",
  initialState: {
    loading: false,
    success: false,
    message: "",
    error: "",
  },
  reducers: {
    hostChangePasswordReset: (state) => {
      state.loading = false;
      state.success = false;
      state.message = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(hostChangePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(hostChangePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.message = action.payload.msg;
      })
      .addCase(hostChangePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostChangeEmailSlice = createSlice({
  name: "hostChangeEmail",
  initialState: {
    loading: false,
    success: false,
    message: "",
    error: "",
  },
  reducers: {
    hostChangeEmailReset: (state) => {
      state.loading = false;
      state.success = false;
      state.message = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(hostChangeEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(hostChangeEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.message = action.payload.msg;
      })
      .addCase(hostChangeEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostPropertiesSlice = createSlice({
  name: "hostProperties",
  initialState: {
    loading: false,
    properties: [],
    error: "",
  },
  reducers: {
    hostPropertiesReset: (state) => {
      state.loading = false;
      state.properties = [];
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHostProperties.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getHostProperties.fulfilled, (state, action) => {
        state.loading = false;
        state.properties = action.payload;
      })
      .addCase(getHostProperties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostPropertyUpdateSlice = createSlice({
  name: "hostUpdateProperty",
  initialState: {
    loading: false,
    success: false,
    message: "",
    error: "",
  },
  reducers: {
    updatePropertyReset: (state) => {
      state.loading = false;
      state.success = false;
      state.message = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateHostProperty.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateHostProperty.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.message = action.payload.msg;
      })
      .addCase(updateHostProperty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostPropertyDetailsSlice = createSlice({
  name: "getPropertyDetails",
  initialState: {
    loading: false,
    property: {},
    error: "",
  },
  reducers: {
    hostPropertyDetailsReset: (state) => {
      state.loading = false;
      state.property = {};
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPropertyDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPropertyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.property = action.payload.property;
      })
      .addCase(getPropertyDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostPropertyDeleteSlice = createSlice({
  name: "deleteProperty",
  initialState: {
    loading: false,
    success: false,
    message: "",
    error: "",
  },
  reducers: {
    deletePropertyReset: (state) => {
      state.loading = false;
      state.success = false;
      state.message = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteHostProperty.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteHostProperty.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(deleteHostProperty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const addHostPropertySlice = createSlice({
  name: "addHostProperty",
  initialState: {
    loading: false,
    success: "",
    msg: "",
    error: "",
  },
  reducers: {
    addPropertyReset: (state) => {
      state.loading = false;
      state.success = false;
      state.msg = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProperty.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProperty.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.msg = action.payload.msg;
      })
      .addCase(addProperty.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostEarningsSlice = createSlice({
  name: "hostEarnings",
  initialState: {
    loading: false,
    success: "",
    earnings: null,
    error: "",
  },
  reducers: {
    hostEarningsReset: (state) => {
      state.loading = false;
      state.success = "";
      state.earnings = null;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHostEarnings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHostEarnings.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.earnings = action.payload.orders;
      })
      .addCase(getHostEarnings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostPendingEarningsSlice = createSlice({
  name: "hostPendingEarnings",
  initialState: {
    loading: false,
    success: "",
    orders: null,
    error: "",
  },
  reducers: {
    hostPendingEarningsReset: (state) => {
      state.loading = false;
      state.success = "";
      state.orders = null;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHostPendingEarnings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHostPendingEarnings.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.orders = action.payload.orders;
      })
      .addCase(getHostPendingEarnings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostAccountBalanceSlice = createSlice({
  name: "hostAccountBalance",
  initialState: {
    loading: false,
    balance: null,
    error: "",
  },
  reducers: {
    hostAccountBalanceReset: (state) => {
      state.loading = false;
      state.success = "";
      state.balance = null;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHostAccountBalance.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHostAccountBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.balance = action.payload;
      })
      .addCase(getHostAccountBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostOrdersSlice = createSlice({
  name: "orders",
  initialState: {
    loading: false,
    orders: [],
    success: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllOrders.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.orders = action.payload.orders;
        state.error = "";
      })
      .addCase(getAllOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostBlurSlice = createSlice({
  name: "hostBlur",
  initialState: false,
  reducers: {
    setBlurOn: (state) => (state = true),
    setBlurOff: (state) => (state = false),
  },
});

const hostSideNavSlice = createSlice({
  name: "hostSideNav",
  initialState: false,
  reducers: {
    setNavOn: (state) => (state = true),
    setNavOff: (state) => (state = false),
  },
});

const hostFlagsSlice = createSlice({
  name: "hostFlags",
  initialState: {
    loading: false,
    automationActive: false,
    automationAllowed: false,
    automationActiveAdmin: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFlags.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFlags.fulfilled, (state, action) => {
        state.loading = false;
        state.automationActive = action.payload.automationActive;
        state.automationAllowed = action.payload.automationAllowed;
        state.automationActiveAdmin = action.payload.automationActiveAdmin;
      })
      .addCase(getFlags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

export const { logout, removeError, hostLoginReset } = hostLoginSlice.actions;
export const { hostSignupReset } = hostSignupSlice.actions;
export const { setNavOn, setNavOff } = hostSideNavSlice.actions;
export const { setBlurOn, setBlurOff } = hostBlurSlice.actions;
export const { updatePropertyReset } = hostPropertyUpdateSlice.actions;
export const { deletePropertyReset } = hostPropertyDeleteSlice.actions;
export const { profileUpdateReset } = hostUpdateProfileSlice.actions;
export const { addPropertyReset } = addHostPropertySlice.actions;
export const { changePasswordReset } = hostResetPasswordSlice.actions;
export const { hostEarningsReset } = hostEarningsSlice.actions;
export const { hostPropertiesReset } = hostPropertiesSlice.actions;
export const { hostPendingEarningsReset } = hostPendingEarningsSlice.actions;
export const { hostPropertyDetailsReset } = hostPropertyDetailsSlice.actions;
export const { hostProfileReset } = hostProfileSlice.actions;
export const { hostChangePasswordReset } = hostChangePasswordSlice.actions;
export const { hostChangeEmailReset } = hostChangeEmailSlice.actions;

export { hostLoginSlice };
export { hostSideNavSlice };
export { hostSignupSlice };
export { hostBlurSlice };
export { hostPropertyUpdateSlice };
export { hostPropertiesSlice };
export { hostPropertyDetailsSlice };
export { hostPropertyDeleteSlice };
export { hostProfileSlice };
export { addHostPropertySlice };
export { hostUpdateProfileSlice };
export { hostResetPasswordSlice };
export { hostEarningsSlice };
export { hostPendingEarningsSlice };
export { hostAccountBalanceSlice };
export { hostOrdersSlice };
export { hostChangePasswordSlice };
export { hostChangeEmailSlice };
export { hostFlagsSlice };
