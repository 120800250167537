import React, { useState, useEffect } from 'react'
import TextBox from '../components/TextBox'
import { Alert, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from "formik";
import { resetSchema } from "../schemas";
import { useSelector, useDispatch } from 'react-redux';
import {  hostChangePassword } from '../store/thunks/hostActions';
import HostNav from '../components/hostComponents/HostNav';
import { hostChangePasswordReset } from '../store/slices/hostSlices';


const ResetPasswordScreen = ({ host, driver }) => {

    const dispatch = useDispatch();

    const [passwordMatchmessage, setPasswordMatchmessage] = useState(null);
    const [confirmPassword, setConfirmpassword] = useState('');
    const [initialValues] = useState({
        newPassword: "",
        oldPassword: ""
    });
    const {
        loading,
        success,
        error,
        message
      } = useSelector((state) => state.hostChangePassword);

    const { values, errors, touched, handleBlur, handleChange } = useFormik(
        {
            initialValues: initialValues,
            validationSchema: resetSchema,
        }
    )

    const params = useParams();
    const navigate = useNavigate()
    const hid = params.hid;

    // USe Seletor ResetPassword
    // const { loading, error, message, success } = useSelector((state) => state.hostResetPassword)
    // const { loading: loadingDriver, error: errorDriver, message: msgDriver, success: successDriver } = useSelector((state) => state.driverResetPassword)

    // Submit Handler
    const submitHandler = async (e) => {

        e.preventDefault();
        setPasswordMatchmessage(null);

        if (values.newPassword !== confirmPassword) {

            setPasswordMatchmessage("Password does not match")
            return
        }
        if (Object.keys(errors).length === 0) {

            // host && dispatch(hostResetPassword({ newPassword: values.newPassword, passwordResetToken }))
            // driver && dispatch(driverResetPassword({ newPassword: values.newPassword, passwordResetToken }))
            const passwordCredentials = { newPassword: values.newPassword, oldPassword: values.oldPassword };
            dispatch(hostChangePassword({ passwordCredentials, hid }));
        }
    }

    useEffect(() => {
        if (success) {
            // For Navigae to profile settings page
            setTimeout(() => {
                dispatch(hostChangePasswordReset());
            }, 2000);

            setTimeout(() => {

                navigate(`/profileSetting/${hid}`)
            }, 3000);
            
        }
    }, [success, navigate, hid]);

    return (
        <>
            <div className='flex w-full h-screen overflow-auto min-h-[500px]'>
                <div className='flex flex-col w-full mobile:w-full px-10 tablet:px-0 mx-auto h-full'>
                    <HostNav dashboard={true} hideproperty={true} />

                

                {/* ALERTS ALL THE Way */}
                {passwordMatchmessage && (
                    <Alert severity="error">
                        <strong>{passwordMatchmessage}</strong>
                    </Alert>
                )}
                {error && (
                    <Alert severity="error">
                        <strong>{error ? error : ""}</strong>
                    </Alert>
                )}
                {(success) && (
                    <Alert severity="success">
                        <strong>{message ? message : ""}</strong>
                    </Alert>
                )}
                {/* ----------------------------------------------------- */}
                <div className="flex flex-col justify-evenly items-center h-[70vh]">

                    <div className="top mt-1 text-center">
                        <h2 className="font-bold text-lg mobile:text-base3">Create New Password</h2>
                        <p class="text-[#919191] text-base2 ">
                            Securely access your account by creating a new <br/> login password
                        </p>
                    </div>

                    <form className="mb-5 text-center w-[450px] mobile:w-[300px]">
                        <TextBox
                            label={"Enter Old Password"}
                            name={"oldPassword"}
                            type={"password"}
                            value={values.oldPassword}
                            onChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors}
                        />
                        {errors.oldPassword && touched.oldPassword ? <p className="text-[red]">{errors.oldPassword}</p> : null}
                        <TextBox
                            label={"Enter New Password"}
                            name={"newPassword"}
                            type={"password"}
                            value={values.newPassword}
                            onChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors}
                        />
                        {errors.newPassword && touched.newPassword ? <p className="text-[red]">{errors.newPassword}</p> : null}
                        <label className="text-base1 font-normal block mb-1 text-left" htmlFor="confirmPassword">
                            Confirm New Password
                        </label>
                        <input
                            name={"confirmPassword"}
                            type={"password"}
                            // placeholder={"confirm password"}
                            value={confirmPassword}
                            onChange={(e) => setConfirmpassword(e.target.value)}
                            className="w-full bg-lightgrey rounded-xl p-[10px] outline-none"
                        />

                        <div className="w-full text-center">
                            <button
                                onClick={submitHandler}
                                className="bg-primary w-full h-[50px] rounded-[40px] mt-10 "
                            >
                                <p className="font-bold text-white text-base2">
                                    {loading ? <CircularProgress color="inherit" className="mr-2" size={'20px'} /> : "Change password"}</p>
                            </button>
                        </div>
                    </form>

                </div>
            </div>
            </div>
        </>
    )
}

export default ResetPasswordScreen