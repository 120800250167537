import { createSlice } from '@reduxjs/toolkit'
import { driverResetPassword } from '../thunks/driverActions';

const driverResetPasswordSlice = createSlice({
    name: "driverResetPassword",
    initialState: {
        loading: false,
        message: null,
        error: '',
        success: false
    },
    reducers: {
        changePasswordReset: (state) => {
            state.loading = false;
            state.message = null;
            state.error = '';
            state.success = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(driverResetPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(driverResetPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.message = action.payload.msg;
                state.success = true;
            })
            .addCase(driverResetPassword.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload?.error
            })
    }
})

export const { changePasswordReset } = driverResetPasswordSlice.actions;
export {driverResetPasswordSlice}