import React, { useState } from 'react';

const CharacterCounter = ({message, setMessage}) => {
  const maxLength = 200;
  const charCount = message ? message.length : 0;

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setMessage(inputValue);
    }
  };

  return (
    <div>
      <textarea
        className="h-60 w-full p-2 border rounded"
        placeholder="Write message here..."
        value={message}
        onChange={handleChange}
      ></textarea>
      <div className="text-gray-500 text-right mt-2">
        {charCount}/{maxLength}
      </div>

    </div>
  );
};

export default CharacterCounter;


