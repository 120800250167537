import React from 'react'
import failedIcon from '../../icons/failed.png'
import { Link } from 'react-router-dom'

const DriverEmailVerificationFailed = ({ error }) => {
  return (
    <div className="w-[90%] m-auto shadow-4xl h-[500px] tablet:h-[300px] bg-white my-7 overflow-auto flex flex-col justify-around items-center mobile:p-5 borde">
      <h1 className='text-base2 font-bold py-2 px-4 text-center'>{error}</h1>
      <img src={failedIcon} className='w-10' alt="failed icon" />

      <p className="text-base2 text-light ">
        Go Back to Login {" "}
        <label className=" text-primary">
          <Link to={"/login"}>Login</Link>
        </label>
      </p>
    </div>
  )
}

export default DriverEmailVerificationFailed