import React, { useEffect } from "react";
import HostNav from "../components/hostComponents/HostNav";
import NewProperty from "../components/hostComponents/AddProperty";
import { addPropertyReset } from "../store/slices/hostSlices";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";

function AddPropertyScreen() {

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const { hostInfo: hostLogin } = useSelector((state) => state.host);

  const { success, msg, error } = useSelector(
    (state) => state.addProperty
  );

  useEffect(() => {
    // if (!hostLogin) {
    //   navigate("/login");
    // }
    if (success) {
      navigate("/host")
    }
  }, [success, navigate, hostLogin]);

  return (
    <>
      {/* //overflow auto to attain sticky position */}
      <div className="flex w-full h-screen overflow-auto">
        {/* RIGHT  */}
        <div className="flex flex-col w-full mobile:w-full px-10 tablet:px-0 mx-auto">
          <HostNav />
          {msg && (
            <Alert severity="success">
              <strong>{msg}</strong>
            </Alert>
          )}
          {error && (
            <Alert severity="error">
              <strong>{error}</strong>
            </Alert>
          )}

          {/* ADD NEW PROPERTY COMPONENT */}
          <NewProperty />
        </div>
      </div>
    </>
  );
}

export default AddPropertyScreen;
