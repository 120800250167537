import React, { useEffect } from 'react'
import HostNav from "../components/hostComponents/HostNav";
import PropertyDetailsComponent from "../components/hostComponents/PropertyDetailsComponent";
import { useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux";

function PropertyDetailsScreen() {

const navigate = useNavigate()

  const { hostInfo } = useSelector((state) => state.host);

  useEffect(() => {

    if (!hostInfo) {
      navigate('/login')
    }
  }, [hostInfo, navigate]);

  return (

    <>
      {/* //overflow auto to attain sticky position */}
      <div className="flex w-full h-screen overflow-auto">

        {/* RIGHT  */}
        <div className="flex flex-col w-full mobile:w-full px-10 tablet:px-0 mx-auto">
          <HostNav />
          <PropertyDetailsComponent />
        </div>
      </div>
    </>
  )
}

export default PropertyDetailsScreen