import request from "../../utils/request";
import { createAsyncThunk } from "@reduxjs/toolkit";

const driverResetPassword = createAsyncThunk(
    "driverResetPassword/driverResetPassword",
    async (credentials, { rejectWithValue }) => {

        const passwordResetToken = credentials.passwordResetToken;
        const newPassword = credentials.newPassword;
        try {
            const { data } = await request.put(`/driver/resetpassword/${passwordResetToken}`, { newPassword }, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export { driverResetPassword }