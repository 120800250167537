import React from 'react'

const OrderTable = ({ orders }) => {

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
    };

    return (
        <div className="relative overflow-x-auto border-[0.5px] border-solid border-[#CCCCCC] rounded-lg mx-2 my-2">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-lightgrey text-black">
                    <tr>
                        <th className="px-4 py-3">
                        Property ID
                        </th>
                        <th className="px-4 py-3">
                            Delivery Address
                        </th>
                        <th className="px-4 py-3">
                            Expected Delivery
                        </th>
                        <th className="px-4 py-3">
                            Commission
                        </th>
                        <th className="px-4 py-3">
                            Status
                        </th>
                    </tr>
                </thead>
                {
                    orders?.length > 0 && orders.map((order, index) => (
                        <tbody>
                            <tr
                                key={order._id}
                                className="bg-white border-b-[#CCCCCC] border-b-[0.5px] py-4"
                            >
                                <td className="px-4 w-fit max-w-[170px]">
                                    <p className='truncate'>{order.propertyID}</p>
                                </td>

                                <td className="px-4 w-fit max-w-[200px]">
                                    <p className='truncate'>{`${order?.property?.address.line1} ${order?.property?.address.line2 ? order?.property?.address.line2 : ''} ${order?.property?.address.city} ${order?.property?.address.state}`}</p>
                                </td>

                                <td className="px-4 w-fit min-w-[120px]">
                                    <p className='truncate'>{new Date(order.inStockDate)?.toLocaleString('en-US', options)}</p>
                                </td>

                                <td className="px-4 w-fit min-w-[50px]">
                                    <p className='truncate'>{(order.hostFee).toFixed(2)}</p>
                                </td>

                                <td className="py-4 px-4 w-fit flex">
                                    <p className={`text-center w-[100px] ${order.status === "completed" ? 'bg-submitGreen': order.status === "picked" || "pending" || "requested" ? 'bg-[#F6BE00]': 'bg-deleteRed'} py-2 px-3 rounded-[21px] text-white`}>
                                        {order.status==='picked'? "picked up": order.status}
                                    </p>
                                </td>
                            </tr>
                        </tbody>

                    )
                    )}
            </table>
        </div>
    )
}

export default OrderTable