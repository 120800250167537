import React from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import emailverification from "../icons/emailverification.svg"

const PleaseVerifyEmailScreen = () => {
    return (
        <div className='w-full h-[80vh]'>
            <Header />
            

            <div className='w-full h-full flex items-center justify-center pb-10'>
              {/* <button onClick={handleClick} className='bg-primary p-2 rounded text-white'>Get OnBoard</button> */}
              <div className="">
                <div className="flex flex-col justify-center items-center">
                  <div>
                    <img src={emailverification} />
                  </div>
                  <h1 className='text-[32px] font-segoe font-semibold my-2'>Email Verification</h1>
                  <p className='my-2'>
                  Before proceeding, please verify your email by checking your inbox
                  </p>

                  <div className="">
                    <p className="text-[14px]">
                      Go back to {" "}
                      <label className=" text-primary">
                        <Link to={"/login"}>Login</Link>
                      </label>
                    </p>
                  </div>
                </div>
              </div>
            </div>


        </div>
    )
}

export default PleaseVerifyEmailScreen