import React from 'react'
import passwordResetSuccess from '../icons/passwordResetSucess.svg'
import Header from '../components/Header'
import { useNavigate } from 'react-router-dom';

const PasswordResetSuccessful = () => {
    const navigate = useNavigate();
  return (
    <div className='w-full h-screen'>
        <Header />
        <div className="h-[500px] bg-white my-7 overflow-auto flex flex-col justify-around items-center">
            <div className="w-[400px] mobile:w-[300px]">
                <img src={passwordResetSuccess} alt="Email Sent" className="w-[200px] mobile:w-[150px] h-[200px] mobile:h-[150px] m-auto" />
                <div className="top mt-7 text-center">
                    <h2 className="font-bold text-base3 mobile:text-base2">You’ve successfully updated your password!</h2>                        
                </div>
                
                <div className='top mt-5 text-center'>
                    <p class="text-[#919191] text-base1 mobile:text-sm">
                        Now you can log in to your Bellhopt account
                    </p>
                    <button className="bg-primary w-full h-[50px] rounded-[40px] mt-4" onClick={() => navigate("/login")}>
                        <p className="font-bold text-white text-base2">Login</p>
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PasswordResetSuccessful