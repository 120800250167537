import request from '../utils/request'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import EmailVerificationFailed from '../components/EmailVerificationFailed'
import EmailVerificationSuccess from '../components/EmailVerificationSuccess'
import Header from '../components/Header'

const VerificationScreen = () => {

    const params = useParams();
    // const navigate = useNavigate()
    const [success, setSuccess] = useState();
    const [error, setError] = useState(false);

    // email token
    const verifcationToken = params.token;

    const verifyEmail = async (token) => {

        try {
            const { data } = await request.get(`/hosts/verify-email/${token}`)

            if (data.success) {
                setSuccess(true);
            }
        } catch (error) {

            setSuccess(false);
            setError(error.response.data.error)
            console.log(error.response.data.error);
        }
    }

    useEffect(() => {

        verifyEmail(verifcationToken);
    }, []);


    return (
        <>

            <div className='w-full h-screen'>

                <Header />
                {success == true ? <EmailVerificationSuccess /> : success == false ? < EmailVerificationFailed error={error} /> : null}

                {/* <EmailVerificationSuccess /> */}
                {/* < EmailVerificationFailed error={"Love you"} /> */}

            </div>
        </>
    )

}

export default VerificationScreen