import React, { useState, useEffect } from "react";
import TextBox2 from "../TextBox2";
import edit from "../../icons/Edit.svg";
import del from "../../icons/Delete.svg";
import copy from "../../icons/Copy.svg";
import back from "../../icons/left.png";
import { updateHostProperty } from "../../store/thunks/hostActions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyDetails } from "../../store/thunks/hostActions";
import { deleteHostProperty } from "../../store/thunks/hostActions";
import { Alert, CircularProgress } from "@mui/material";
import { updatePropertyReset } from "../../store/slices/hostSlices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const getInitialHideAddressState = () => {
  const storedValue = localStorage.getItem("hideAddress");
  return storedValue ? JSON.parse(storedValue) : false; // Default to false if there's no stored value
};

function PropertyDetailsComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [checkInTime, setCheckInTime] = useState();
  const [checkOutTime, setCheckoutTime] = useState();

  const handleTimeChange = (selectedTime) => {
    setCheckInTime(selectedTime);
  };

  const handleTimeChange2 = (selectedTime) => {
    setCheckoutTime(selectedTime);
  };

  const { pid } = params;
  const [queryParam] = useSearchParams();
  const [editMode, setEditMode] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [copyText, setCopyText] = useState("Message");
  // Property Details UseSelector Hook
  const { property: property_d } = useSelector(
    (state) => state.propertyDetails
  );

  // Update Property UseSelector Hook
  const {
    loading: loadingUpdate,
    success: successUpdate,
    error: errorUpdate,
  } = useSelector((state) => state.propertyUpdate);

  const [address, setAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const [credentials, setCredentials] = useState({
    description: "",
    keyInfo: "",
    listingTitle: ""
  });

  const [hideAddress, setHideAddress] = useState(getInitialHideAddressState);

  const handleCheckboxChange = () => {
    setHideAddress(!hideAddress);
  };

  // Use useEffect to update localStorage when hideAddress changes
  useEffect(() => {
    localStorage.setItem("hideAddress", JSON.stringify(hideAddress));
  }, [hideAddress]);
  // onClick Handlers
  const handleEditSubmit = () => {
    const propertyCredentials = {
      checkInTime,
      checkOutTime,
      ...credentials,
      address,
      hideAddress,
    };

    console.log(propertyCredentials);
    dispatch(updateHostProperty({ propertyCredentials, pid }));
  };

  const handleDeleteBtn = () => {
    if (window.confirm("Are you sure you want to delete this property?")) {
      dispatch(deleteHostProperty(pid));
      navigate("/host");
    }
  };

  function unsecuredCopyToClipboard(text, description) {
    const textArea = document.createElement("textarea");
    textArea.value = description + " " + text; // Attach the description text before the link
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  }

  useEffect(() => {
    if (queryParam.get("edit")) {
      setEditMode(true);
      setIsReadOnly(false);
    }

    if (successUpdate) {
      setIsReadOnly(true);
      setEditMode(false);

      navigate("/host");
    } else {
      if (!property_d?._id || property_d?._id !== pid) {
        dispatch(getPropertyDetails(pid));
      } else {
        setAddress({ ...property_d.address });
        setCredentials({
          description: property_d.description,
          keyInfo: property_d.keyInfo,
          listingTitle: property_d.listingTitle
        });
      }
    }

    dispatch(getPropertyDetails(pid));
  }, [dispatch, pid, property_d?._id, navigate, successUpdate]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(updatePropertyReset());
    }, 2000);
  }, [errorUpdate]);

  const dateObject = new Date(property_d.checkInTime);

  // Format the time portion of the date object using toLocaleTimeString
  const timeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const formattedTime = dateObject.toLocaleTimeString(
    undefined,
    timeFormatOptions
  );

  // console.log("property ", property_d.checkInTime)
  // console.log("updated time ", formattedTime)

  const dateObject2 = new Date(property_d.checkOutTime);
  const checkoutFormat = { hour: "numeric", minute: "numeric", hour12: true };
  const formattedCheckoutTime = dateObject2.toLocaleTimeString(
    undefined,
    checkoutFormat
  );

  return (
    <>
      {/* Error */}
      {errorUpdate && <Alert severity="warning">{errorUpdate}</Alert>}

      <div className="m-auto shadow-4xl pb-[30px] bg-white my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 overflow-scroll">
        <div className="w-full m-auto">
          <div className="flex justify-between items-center px-5 py-3 border-b-lightborder border-b-2 w-full">
            <h1 className="text-base3 font-segoe font-semibold mobile:text-base2 flex items-center gap-x-2">
              <img
                src={back}
                alt="back"
                className="w-[30px] h-[20px] inline-block pr-2 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <label>Property Details </label>
            </h1>

            {/* Buttons */}
            <div className="flex laptop:gap-5 tablet:gap-5 mobile:gap-0">
              <button
                className="bg-lightgrey flex items-center h-fit py-2 px-3 mobile:p-0 m-auto rounded-lg mobile:w-[40px] mobile:h-[40px] mobile:rounded-[50%] w-fit cursor-pointer"
                onClick={(e) => {
                  const description =
                    "I also offer a grocery delivery and stocking service through Bellhopt for my guests to make grocery shopping less stressful and time-consuming, especially in a new place. They deliver fresh, high-quality products to your rental before you even arrive. If you’d like to order you can order directly to the rental Order Link: ";
                  unsecuredCopyToClipboard(property_d.uniqueLink, description);

                  // alert(property.uniqueLink);
                  setCopyText("Copied!");
                  setTimeout(() => {
                    setCopyText("Message");
                  }, 3000);
                  e.stopPropagation();
                  // Message Component
                }}
              >
                <p className="text-light font-segoe font-normal mobile:hidden w-fit min-w-[70px] flex justify-center items-center">
                  {copyText}
                </p>
                <img
                  src={copy}
                  className="w-[30px] mobile:min-w-[40px] px-2 py-1 mobile:p-3"
                  alt=""
                />
              </button>

              {!editMode ? (
                <img
                  onClick={() => {
                    setEditMode(true);
                    setIsReadOnly(false);
                  }}
                  src={edit}
                  alt="Edit"
                  className="w-[40px] mobile:w-[40px] bg-lightgrey rounded-[50%] mobile:p-3 p-2 overflow-visible cursor-pointer"
                />
              ) : (
                <button
                  onClick={() => {
                    handleEditSubmit();
                  }}
                  className="w-[100px] bg-[#00B227] text-white font-bold py-2 px-4 rounded-lg mobile:block m-auto cursor-pointer"
                >
                  {loadingUpdate ? (
                    <CircularProgress
                      color="inherit"
                      className="mr-2"
                      size={"20px"}
                    />
                  ) : (
                    "Update"
                  )}
                </button>
              )}
              <img
                onClick={handleDeleteBtn}
                src={del}
                alt="Delete"
                className="w-[40px] mobile:w-[40px] bg-lightgrey rounded-[50%] mobile:p-3 p-2 cursor-pointer"
              />
            </div>
          </div>

          <div className="w-[95%] m-auto form pt-5">
            <div className="flex items-center gap-x-7 mobile:flex-col">
              <TextBox2
                label={"Address Line 1"}
                name={"line1"}
                credentials={address}
                value={address.line1}
                setValue={setAddress}
                readOnly={isReadOnly}
              />
              <TextBox2
                label={"Address Line 2"}
                name={"line2"}
                credentials={address}
                value={address.line2}
                setValue={setAddress}
                readOnly={isReadOnly}
              />
            </div>

            <div className="flex items-center gap-x-7 mobile:flex-col">
              <TextBox2
                label={"City"}
                name={"city"}
                credentials={address}
                value={address.city}
                setValue={setAddress}
                readOnly={isReadOnly}
              />
              <TextBox2
                label={"State"}
                name={"state"}
                credentials={address}
                value={address.state}
                setValue={setAddress}
                readOnly={isReadOnly}
              />
              <TextBox2
                label={"Zip Code"}
                name={"zipCode"}
                credentials={address}
                value={address.zipCode}
                setValue={setAddress}
                readOnly={isReadOnly}
              />
            </div>
            <TextBox2
              label={"Listing Title"}
              name={"listingTitle"}
              credentials={credentials}
              value={credentials.listingTitle}
              setValue={setCredentials}
              readOnly={isReadOnly}
            />

            <div>
              <label>Availability:</label>

              <div className="flex justify-between gap-[20px]">
                <div className="w-full text-left my-2">
                  <label
                    className="text-base1 font-normal block mb-1"
                    htmlFor="time"
                  >
                    <p className="font-segoe font-semibold text-[18px]">
                      Check In
                    </p>
                  </label>
                  <DatePicker
                    selected={checkInTime}
                    value={checkInTime ? checkInTime : formattedTime}
                    onChange={handleTimeChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="w-full bg-lightgrey p-[10px] outline-none cursor-pointer rounded-xl "
                    readOnly={isReadOnly}
                  />
                </div>

                <div className="w-full text-left my-2">
                  <label
                    className="text-base1 font-normal block mb-1"
                    htmlFor="time"
                  >
                    <p className="font-segoe font-semibold text-[18px]">
                      Check Out
                    </p>
                  </label>
                  <DatePicker
                    selected={checkOutTime}
                    value={checkOutTime ? checkOutTime : formattedCheckoutTime}
                    onChange={handleTimeChange2}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="w-full bg-lightgrey p-[10px] outline-none cursor-pointer rounded-xl "
                    readOnly={isReadOnly}
                  />
                </div>
              </div>
            </div>

            <div className="w-full text-left mt-2">
              <label
                className="text-base1 font-normal block mb-1"
                htmlFor="message"
              >
                Entry Instructions
              </label>
              <textarea
                className={`w-full bg-lightgrey rounded-xl p-2 outline-none cursor-${
                  isReadOnly ? "default" : "text"
                }`}
                id="message"
                rows="4"
                placeholder="Use the Instructions to better help our driver i.e. (Upon arrival at the rental property, locate the lockbox near the entrance. The provided code is 1234. Please remember to return the key to the lockbox and scramble the numbers for security.)"
                name="description"
                value={credentials.description}
                readOnly={isReadOnly}
                onChange={(e) =>
                  setCredentials({
                    ...credentials,
                    [e.target.name]: e.target.value,
                  })
                }
              ></textarea>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                checked={hideAddress}
                onChange={isReadOnly ? null : handleCheckboxChange}
                className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                readOnly={isReadOnly}
              />
              <span className="ml-2 text-gray-700">Hide Address</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyDetailsComponent;
