import React from "react";
import bell from "../icons/email-logo.svg";
import bg from "../icons/email-bg.svg";
import vector2 from "../icons/email-vector2.svg";
import vector3 from "../icons/email-vector3.svg";
import vector4 from "../icons/email-vector4.svg";

import vector2img from "../icons/email-vector2-img.svg";
import vector3img1 from "../icons/email-vector3-img1.svg";
import vector4img from "../icons/email-vector4-img.svg";

import welcome from "../icons/welcome.svg";

function Email() {
  const divStyle = {
    backgroundImage: `url(${bg})`,
    // backgroundSize: "cover",
    width: "100%",
    backgroundRepeat: "no-repeat",
    height: "400px",
  };
  const divStyle1 = {
    backgroundImage: `url(${vector2})`,
    // backgroundSize: "cover",
    width: "100%",
    backgroundRepeat: "no-repeat",
  };
  const divStyle2 = {
    backgroundImage: `url(${vector3})`,
    // backgroundSize: "cover",
    width: "100%",
    backgroundRepeat: "no-repeat",
  };
  const divStyle3 = {
    backgroundImage: `url(${vector4})`,
    // backgroundSize: "cover",
    width: "100%",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="w-full flex justify-center">
      <div className="w-[600px]  border border-lightborder">
        <div className="flex justify-center my-5">
          <img src={bell} alt="logo" />
        </div>

        <div className="w-full h-full flex justify-center">
          <div className="w-[526px]">
            <div style={divStyle}>
              <div className="flex items-center justify-center border-b border-white">
                <h1 className="text-[20px] text-white font-bold py-5 px-10 text-center">
                  Elevate Your Stay with Bellhopt's Grocery Delivery
                </h1>
              </div>

              <div className="flex justify-center mt-5">
                <img src={welcome} alt="welcome" />
              </div>

              <div className="flex justify-center">
                <p className=" text-[white] text-center py-3">
                  to Host (zohaib)
                </p>
              </div>

              <div className="w-[90%] m-auto">
                <p className=" text-[white] text-center py-3 text-[13px] opacity-[0.8]">
                  Get ready for a stress-free and convenient stay with
                  Bellhopt's Seamless Pre-Arrival Grocery Delivery.
                </p>
              </div>
            </div>

            <div className="w-[90%] m-auto">
              <h1 className="text-center text-[17px]">
                What's{" "}
                <a href="https://bellhopt.com" className="text-[#EF4949]">
                  Bellhopt{" "}
                </a>{" "}
                & How it Works?
              </h1>

              <p>
                Imagine stepping into your accommodations and finding your
                fridge stocked with fresh groceries. With Bellhopt, you can skip
                the grocery store entirely and indulge in the convenience of
                having your fridge ready before you even step in.
                <br />
                <br />
              </p>

              <div>
                <ol className="list-decimal pl-4">
                  <li>
                    <span className="font-bold text-[17px]">
                      Browse & Order:
                    </span>{" "}
                    Use the link provided by Alex to explore our local food
                    selection and place your order account creation is not
                    required.
                  </li>
                  <li>
                    <span className="font-bol text-[17px]">
                      Delivered to You:
                    </span>{" "}
                    Your chosen items will be delivered and stocked before you
                    arrive.
                  </li>
                </ol>
              </div>
            </div>

            <div style={divStyle1} className="mt-10 relative">
              <div className="w-[90%] m-auto">
                <div className="flex items-center gap-[20px] py-[100px]">
                  <div className="w-[30%]">
                    <img
                      src={vector2img}
                      alt="vector2img"
                      width="140px"
                      height="130px"
                    />
                  </div>
                  <div className="w-[70%]">
                    <h1 className="font-bold text-[24px]">
                      Enjoy a{" "}
                      <span className="text-[white] bg-[#EF4949]"> 5% </span>{" "}
                      discount on your first order!
                    </h1>
                    <p className=" text-[17px]">
                      Use code <span className="text-[#EF4949]">"FIRST5" </span>{" "}
                      at checkout for a deal
                    </p>

                    <p className="py-5">
                      Just use the link{" "}
                      <a className="text-[blue]">
                        http//www.bellhopt.com/prodcts/view{" "}
                      </a>
                      for a hassle-free experience. Shop Now!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div style={divStyle2} className="relative bottom-[55px]">
              <div className="w-[90%] m-auto py-10">
                <h1 className="text-center text-[24px] ">Our Products</h1>

                <div className="pt-5">
                  <div className="flex justify-between w-full">
                    <div className="w-[47%]">
                      <img src={vector3img1} alt="vector3img1" />
                      <h1 className="text-center font-bold">Fresh Fruits</h1>
                      <p className="text-[12px] text-center">
                        Amet minim mollit non deserunt ullamco est sit aliqua.
                      </p>

                      <div className="flex justify-center py-5">
                        <button className="border border-[#EF4949] rounded-[50px]">
                          <p className="py-2 px-7 text-[#EF4949] text-[11px] font-bold ">
                            Shop now
                          </p>
                        </button>
                      </div>
                    </div>
                    <div className="w-[47%]">
                      <img src={vector3img1} alt="vector3img1" />
                      <h1 className="text-center font-bold">Fresh Fruits</h1>
                      <p className="text-[12px] text-center">
                        Amet minim mollit non deserunt ullamco est sit aliqua.
                      </p>
                      <div className="flex justify-center py-5">
                        <button className="border border-[#EF4949] rounded-[50px]">
                          <p className="py-2 px-7 text-[#EF4949] text-[11px] font-bold ">
                            Shop now
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div></div>
              </div>
            </div>

            <div style={divStyle3} className="relative bottom-[80px]">
              <div className="w-[90%] m-auto">
                <div className="flex items-center justify-between py-[30px] gap-[40px]">
                  <div className="w-[50%]">
                    <img
                      src={vector4img}
                      alt="vector2img"
                      width="300px"
                      height="300px"
                    />
                  </div>
                  <div className="w-[50%]">
                    <h1 className="font-bold text-[24px]">Buy Packages</h1>
                    <p className=" text-[17px]">
                      Lorem ipsum dollar sign is the placeholder used in the
                      graphics.
                    </p>

                    <div className=" py-5">
                      <button className="border border-[#EF4949] rounded-[50px]">
                        <p className="py-2 px-7 text-[#EF4949] text-[11px] font-bold ">
                          Shop now
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[black] relative bottom-[80px]">
              <div className="w-[90%] m-auto py-10 text-[white]">
                <p>For questions, reach out to support@bellhopt.com</p>
                <p>
                  We're excited to elevate your stay with Bellhopt's Delivery &
                  Stocking.{" "}
                </p>

                <p>Warm regards,</p>
                <p>[Property manager]</p>
                <p>(949) 330-0735</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Email;
