import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";

const headers = [
  { label: "Property Link", key: "uniqueLink" },
  { label: "Property Address", key: "address" }, // Add address key
  { label: "Message", key: "uniqueLinkText" },
];

const description = `I'm always looking for ways to elevate your stay, and I think you'll love this! We've partnered with Bellhopt to offer affordable pre-arrival grocery delivery.
            No more initial store runs; let's get your vacation started on the right foot! The best part? No need to create an account.
            Simply order directly to your rental here: `;

const CsvExport = () => {
  const [downloadedData, setDownloadedData] = useState([]);
  // console.log(
  //   "🚀 ~ file: CsvExport.js:14 ~ CsvExport ~ downloadedData:",
  //   downloadedData
  // );
  const csvDownloadRef = useRef(null);

  const { hostInfo } = useSelector((state) => state.host);
  const { properties } = useSelector((state) => state.hostProperties);

  useEffect(() => {
    const prepareData = async () => {
      if (properties) {
        const newData = await properties.map((property) => ({
          uniqueLink: property.uniqueLink,
          address: `${property.address.line1} ${
            property.address.line2 ? property.address.line2 : ""
          }, ${property.address.city}, ${property.address.state} ${
            property.address.zipCode
          }`,
          uniqueLinkText: hostInfo.uniqueLinkText
            ? hostInfo.uniqueLinkText
            : description + property.uniqueLink,
        }));
        setDownloadedData(newData);
      }
    };

    prepareData();
  }, [properties, hostInfo.uniqueLinkText]);

  const exportCSV = () => {
    csvDownloadRef.current.link.click();
    console.log("Export button clicked.");
  };
  return (
    <div>
      <button
        className="flex justify-center items-center bg-black text-white font-bold py-3 px-4 rounded-[100px] tablet:rounded-[5px] min-w-[140px] tablet:w-full tablet:mt-3"
        onClick={exportCSV}
      >
        Export
      </button>
      <CSVLink
        headers={headers}
        data={downloadedData}
        filename="properties.csv"
        className="hidden"
        ref={csvDownloadRef}
        target="_blank"
      />
    </div>
  );
};

export default CsvExport;
