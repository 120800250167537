import React, { useState, useEffect } from 'react'
import TextBox from '../components/TextBox'
import { Alert, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from "formik";
import { useSelector, useDispatch } from 'react-redux';
import HostNav from '../components/hostComponents/HostNav';
import { forgetSchema } from '../schemas';
import { hostChangeEmail } from '../store/thunks/hostActions';
import { hostChangeEmailReset } from '../store/slices/hostSlices';
import emailSent from '../icons/emailSent.svg'

const ChangeEmailScreen = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate()
    const hid = params.hid;
    const {
        loading,
        success,
        error,
        message
    } = useSelector((state) => state.hostChangeEmail);

    const { values, errors, touched, handleBlur, handleChange } = useFormik(
        {
            initialValues: {
                email: "",
            },
            validationSchema: forgetSchema,
        }
    )

    const submitHandler = async (e) => {
        e.preventDefault();
        console.log(errors)
        if (Object.keys(errors).length === 0) {
            const emailCredentials = { email: values.email}
            dispatch(hostChangeEmail({ emailCredentials, hid }))
        }
    }

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                dispatch(hostChangeEmailReset());
            }, 2000)
        }

        // return () => {
        //     dispatch(hostChangeEmailReset());
        // }
    }, [success, error, dispatch])

    
    
  return (
        <>
            <div className='flex w-full h-screen overflow-auto min-h-[500px]'>
                <div className='flex flex-col w-full mobile:w-full px-10 tablet:px-0 mx-auto h-full'>
                    <HostNav dashboard={true} hideproperty={true} />

                

                {/* ALERTS ALL THE Way */}
                
                {error && (
                    <Alert severity="error">
                        <strong>{error ? error : ""}</strong>
                    </Alert>
                )}
                {(success) && (
                    <Alert severity="success">
                        <strong>{message ? message : ""}</strong>
                    </Alert>
                )}
                {/* ----------------------------------------------------- */}
                <div className="flex flex-col justify-evenly items-center h-[70vh]">
                    {
                        success ? (
                            <>
                                <div>
                                <img src={emailSent} alt="Email Sent" className="w-[200px] mobile:w-[150px] h-[200px] mobile:h-[150px] m-auto" />
                                <div className="top mt-7 text-center">
                                    <h2 className="font-bold text-base3 mobile:text-base2">Check Your Inbox</h2>                        
                                </div>
                                <div className='top text-center'>
                                    <p class="text-[#919191] text-base1 mobile:text-sm w-[500px] mobile:w-[300px]">
                                    We’ve sent you an email with a verification link to <b className='text-black'>{values.email}</b>. Your email will be updated once you verify.
                                    </p>
                                </div>
                                {/* <div className='top mt-5 text-center'>
                                    <p className="text-base1 text-light">
                                        Didn't get the email?
                                    </p>
                                    <button className="bg-primary w-full h-[50px] rounded-[40px] mt-1" >
                                        <p className="font-bold text-white text-base2">Send Again</p>
                                    </button>
                                </div> */}
                            </div> 
                            </>
                            )
                            :
                            (
                                <>
                                    <div className="top mt-1 text-center">
                        <h2 className="font-bold text-lg mobile:text-base3">Change Email Address</h2>
                        {/* <p class="text-[#919191] text-base2 ">
                            Securely access your account by creating a new <br/> login password
                        </p> */}
                    </div>

                    <form className="mb-5 text-center w-[450px] mobile:w-[300px]">
                        <TextBox
                            label={"Enter New Email Address"}
                            name={"email"}
                            type={"email"}
                            value={values.email}
                            onChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors}
                        />
                        {errors.email && touched.email ? <p className="text-[red]">{errors.email}</p> : null}
                        
                        

                        <div className="w-full text-center">
                            <button
                                onClick={submitHandler}
                                className="bg-primary w-full h-[50px] rounded-[40px] mt-10 "
                            >
                                <p className="font-bold text-white text-base2">
                                    {loading ? <CircularProgress color="inherit" className="mr-2" size={'20px'} /> : "Change Email"}</p>
                            </button>
                        </div>
                    </form>
                                </>
                            )
                    }
                    

                </div>
            </div>
            </div>
        </>
    )
}

export default ChangeEmailScreen