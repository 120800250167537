import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import request from '../utils/request';
import { Alert, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import HostNav from '../components/hostComponents/HostNav';

const SupportScreen = () => {

    const { hostInfo: Info } = useSelector(
        (state) => state.host
    )

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [contactMessage, setContactMessage] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);

    // declare all the handlers
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!contactMessage) {
            setError("Message cannot be empty")
            return
        }

        setLoading(true);
        try {
            const res = await request.post(`/contact`, { name, email, phone, message: contactMessage });
            console.log(res)
            if (res.data.success) {
                setError(null)
                setLoading(false)
                setSuccess(res.data.message)
            }
        }
        catch (err) {
            setError(err.response.data.error)
            setLoading(false)
        }
        e.preventDefault();
    }

    useEffect(() => {
        setName(Info ? Info?.firstName + " " + Info?.lastName : "")
        setEmail(Info ? Info.email : "")
        setPhone(Info ? Info.phoneNumber : "")
    }, [Info])

    useEffect(() => {
        setTimeout(() => {
            error && setError(null)
            success && setSuccess(null)
        }, 3000)
    }, [error, success])

    return (
        <div className="flex flex-col min-h-screen w-full h-fit px-10 tablet:px-0">
            {Info ? <HostNav /> : <Header />}

            <div className="w-full m-auto shadow-4xl h-fit bg-white my-7 flex  flex-col justify-around items-start p-2 rounded-lg tablet:w-[90%]">

                <div className='h-fit w-full'>
                    {success && (
                        <Alert
                            severity="success"
                            sx={{
                                position: "fixed",
                                width: "90%",
                                margin: "auto",
                                top: 10,
                                left: 0,
                                right: 0,
                                zIndex: 10,
                            }}
                        >
                            {success}
                        </Alert>
                    )}
                    {error && (
                        <Alert
                            severity="warning"
                            sx={{
                                position: "fixed",
                                width: "90%",
                                margin: "auto",
                                top: 10,
                                left: 0,
                                right: 0,
                                zIndex: 10,
                            }}
                        >
                            {error}
                        </Alert>
                    )}

                    <div className="flex flex-col p-5 w-full rounded-lg">

                        <h1 className='text-lg m-aut font-bold'>Contact us</h1>
                        <form onSubmit={(e)=>handleSubmit(e)}>
                            <div className="flex flex-col gap-1">
                                <label htmlFor="guestPhone" className="bg-white text-light">
                                    Name
                                </label>
                                <input
                                    className="outline-none border border-lightborder w-full px-3 py-2 overflow-auto rounded-lg"
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    readOnly={Info ? true : false}
                                />
                            </div>

                            <div className="w-full mt-2">
                                <div className="flex tablet:flex-col gap-5 mt-2">

                                    <div className="w-full flex flex-col gap-1">

                                        <label htmlFor="guestPhone" className="bg-white text-light">
                                            Phone Number
                                        </label>
                                        <input
                                            className="outline-none border border-lightborder w-full px-3 py-2 overflow-auto rounded-lg"
                                            type="text"
                                            name="phone"
                                            placeholder="+1 949 786 9999"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            required
                                            readOnly={Info ? true : false}
                                        />
                                    </div>

                                    <div className="w-full flex flex-col gap-1">

                                        <label htmlFor="guestEmail" className="bg-white text-light">
                                            Email
                                        </label>
                                        <input
                                            className="outline-none border border-lightborder w-full px-3 py-2 overflow-auto rounded-lg"
                                            type="email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            readOnly={Info ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* message box */}
                            <div className="w-full flex flex-col gap-2 mt-4">
                                <label htmlFor="message" className="bg-white text-light">
                                    Message
                                </label>
                                <div className="w-full flex flex-col gap-1">
                                    <textarea
                                        className="outline-none border border-lightborder w-full px-3 py-2 rounded-lg"
                                        type="text"
                                        name="message"
                                        rows={5}
                                        placeholder="Type your message here..."
                                        value={contactMessage}
                                        onChange={(e) => setContactMessage(e.target.value)}
                                        required
                                    />
                                </div>

                                <button
                                    className={`w-fit px-10 ml-auto bg-primary text-white font-semibold text-[20px] py-2 rounded-lg`}
                                    disabled={loading}
                                    type="submit"
                                >
                                    {loading ? <CircularProgress color="inherit" className="mr-2" size={'20px'} /> : "Submit"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div >

    )
}

export default SupportScreen