import React, { useState, useEffect } from 'react'
import TextBox from '../components/TextBox'
import Header from '../components/Header'
import { Alert, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from "formik";
import { forgotPasswordSchema } from "../schemas";
import { useSelector, useDispatch } from 'react-redux';
import { changePasswordReset } from '../store/slices/hostSlices';
import { hostResetPassword } from '../store/thunks/hostActions';
import { driverResetPassword } from '../store/thunks/driverActions';


const ChangePasswordScreen = ({ host, driver }) => {

    const dispatch = useDispatch();

    const [passwordMatchmessage, setPasswordMatchmessage] = useState(null);
    const [confirmPassword, setConfirmpassword] = useState('');
    const [initialValues] = useState({
        newPassword: ""
    });

    const { values, errors, touched, handleBlur, handleChange } = useFormik(
        {
            initialValues: initialValues,
            validationSchema: forgotPasswordSchema,
        }
    )

    const params = useParams();
    const navigate = useNavigate()
    const passwordResetToken = params.token;

    // USe Seletor ResetPassword
    const { loading, error, message, success } = useSelector((state) => state.hostResetPassword)
    const { loading: loadingDriver, error: errorDriver, message: msgDriver, success: successDriver } = useSelector((state) => state.driverResetPassword)

    // Submit Handler
    const submitHandler = async (e) => {
        e.preventDefault();
        // console.log(host, driver)
        setPasswordMatchmessage(null);

        if (values.newPassword !== confirmPassword) {

            setPasswordMatchmessage("Password does not match")
            return
        }
        console.log(errors)
        if (Object.keys(errors).length === 0) {
            host && dispatch(hostResetPassword({ newPassword: values.newPassword, passwordResetToken }))
            driver && dispatch(driverResetPassword({ newPassword: values.newPassword, passwordResetToken }))
        }
    }

    useEffect(() => {
        if (success || successDriver) {
            // for Message alert
            navigate('/password-reset/successful')
        }
    }, [success, navigate, dispatch, host, driver, successDriver]);

    return (
        <>
            <div className='w-full h-screen'>

                <Header />

                {/* ALERTS ALL THE Way */}
                {passwordMatchmessage && (
                    <Alert severity="error">
                        <strong>{passwordMatchmessage}</strong>
                    </Alert>
                )}
                {error && (
                    <Alert severity="error">
                        <strong>{error ? error : errorDriver}</strong>
                    </Alert>
                )}
                {(success || successDriver) && (
                    <Alert severity="success">
                        <strong>{message ? message : msgDriver}</strong>
                    </Alert>
                )}
                {/* ----------------------------------------------------- */}
                <div className=" h-[500px] tablet:h-[400px] bg-white my-7 overflow-auto flex flex-col justify-around items-center">

                    <div className="top mt-1 text-center">
                        <h2 className="font-bold text-lg mobile:text-base3">Create New Password</h2>
                        <p className="text-[#919191] text-base2 ">
                            Securely access your account by creating a new <br/> login password
                        </p>
                    </div>

                    <form className="mb-5 text-center w-[450px] mobile:w-[300px]">
                        <TextBox
                            label={"Enter New Password"}
                            name={"newPassword"}
                            type={"password"}
                            value={values.newPassword}
                            onChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors}
                        />
                        {errors.newPassword && touched.newPassword ? <p className="text-[red]">{errors.newPassword}</p> : null}
                        <label className="text-base1 font-normal block mb-1 text-left" htmlFor="confirmPassword">
                            Confirm New Password
                        </label>
                        <input
                            name={"confirmPassword"}
                            type={"password"}
                            // placeholder={"confirm password"}
                            value={confirmPassword}
                            onChange={(e) => setConfirmpassword(e.target.value)}
                            className="w-full bg-lightgrey h-[50px] rounded-lg p-3 outline-none"
                        />

                        <div className="w-full text-center">
                            <button
                                onClick={submitHandler}
                                className="bg-primary w-full h-[50px] rounded-[40px] mt-10 "
                            >
                                <p className="font-bold text-white text-base2">
                                    {loading ? <CircularProgress color="inherit" className="mr-2" size={'20px'} /> : "Submit"}</p>
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </>
    )
}

export default ChangePasswordScreen