import React from 'react'
import HostNav from "../components/hostComponents/HostNav";
import AdminComponent from '../components/hostComponents/AdminComponent';



function Admin() {
  return (
    <>
    <div className="flex w-full h-screen overflow-auto">
        <div className="flex flex-col w-full mobile:w-full px-10 tablet:px-0 mx-auto">
          <HostNav dashboard={true} hideproperty={true}/>
            <AdminComponent/>
        </div>
      </div>


</>
  )
}

export default Admin